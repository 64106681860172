import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import awardsLogo from 'assets/images/oaslogo.png';
import { useAuthorization, useNavigationLinks } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';
import { SessionContext } from 'contexts';
import Dashboard from 'views/Admin/Dashboard/Dashboard';

function Landing() {
  const { getURL } = useNavigationLinks();
  const { checkPermission } = useAuthorization();
  const { sessionStore } = useContext(SessionContext);

  const landingText = () => {
    return sessionStore.bureauName ? (
      <>
        This web application allows for the bureau processing of Superior Honor Awards for LE Staff. Select a menu
        option to continue.
      </>
    ) : (
      <p className="card-text text-center">
        This web application allows you to create an LE Staff&nbsp;
        <b>Awards Nomination,</b>
        &nbsp;send it to Supervisors for approval and then to HR analysis. Click the button to create a new nomination.
      </p>
    );
  };

  const showDashBoard = true;

  return (
    <>
      {showDashBoard && <Dashboard />}
      {!showDashBoard && (
        <div>
          <div className="col-12">
            <div className="row justify-content-center align-self-center mt-5">
              <div className="ol-12">
                {checkPermission('create_nomination') ? (
                  <div className="card mb-3">
                    <div className="card-body">
                      <p className="card-text text-center">
                        This web application allows you to create an LE Staff&nbsp;
                        <b>Awards Nomination,</b>
                        &nbsp;send it to Supervisors for approval and then to HR analysis. Click the button to create a
                        new nomination
                      </p>
                      <div className="text-center">
                        <Link to={getURL('new_nomination_form')} className="text-center btn btn-primary btn-lg">
                          <FontAwesomeIcon icon={faAward} className="mr-2" />
                          New Nomination
                        </Link>
                      </div>
                      <br />
                    </div>
                  </div>
                ) : (
                  <div className="card mb-3">
                    <div className="card-body">
                      This web application allows for the Bureau processing of Superior Honor Awards for LE Staff.
                      Select a menu option to continue.
                      <div className="card-title text-center m-auto max-w-10">
                        <img className="mx-auto max-w-10" style={{ width: '150px' }} src={awardsLogo} alt="Awards" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Landing;
