import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { requestStatus } from 'constants';

function Grid({
  children,
  items,
  totalRecords,
  selectedRecords,
  dataKey,
  onSelectionChange,
  sortMode,
  sortOrder,
  sortField,
  rowGroupMode,
  groupRowsBy,
  showGridlines,
  globalFilter,
  onValueChange,
  lazyParams,
  setLazyParamsFn,
}) {
  const ref = useRef(null);
  const minRows = lazyParams?.rows ?? 10;
  const showPaginator = items?.length  > minRows || totalRecords > minRows;

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const onPageHandler = (e) => {
    if (lazyParams) {
        const params = { ...lazyParams, ...e, requestStatus: requestStatus.REQUEST_NOT_INITIATED };
        setLazyParamsFn(params);
    }

    if (ref && ref.current) {
        window.scrollTo(0, ref.current?.offsetParent?.offsetTop ?? 0);
    }
};

const onSort = (event) => {
  const params = { ...lazyParams, ...event, requestStatus: requestStatus.REQUEST_NOT_INITIATED };
  setLazyParamsFn(params);
};

  return (  
    <DataTable
      className="p-datatable-striped"
      metaKeySelection={false}
      selection={selectedRecords}
      onSelectionChange={onSelectionChange}
      paginator={showPaginator}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReports"
      paginatorLeft={paginatorLeft}
      paginatorRight={paginatorRight}
      rows={minRows}
      rowsPerPageOptions={[10, 20, 50, totalRecords]}
      value={items}
      dataKey={dataKey}
      responsive
      autoLayout
      rowReorder
      sortMode={sortMode}
      totalRecords={totalRecords}
      emptyMessage="No records found"
      rowGroupMode={rowGroupMode}
      groupRowsBy={groupRowsBy}
      showGridlines={showGridlines}
      globalFilter={globalFilter}
      onValueChange={onValueChange}
      lazy={lazyParams}
      first={lazyParams?.first}
      onPage={lazyParams && lazyParams && onPageHandler}
      onSort={lazyParams && onSort}
      sortField={ (lazyParams && lazyParams.sortField) ?? sortField}
      sortOrder={(lazyParams && lazyParams.sortOrder) ?? sortOrder}
    >
      {children}
    </DataTable>
  );
}

Grid.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.array,
  totalRecords: PropTypes.number.isRequired,
  selectedRecords: PropTypes.array,
  dataKey: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func,
  sortMode: PropTypes.string,
  rowGroupMode: PropTypes.string,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  groupRowsBy: PropTypes.string,
  showGridlines: PropTypes.bool,
  globalFilter: PropTypes.array,
  onValueChange: PropTypes.func,
  lazyParams: PropTypes.object,
  setLazyParamsFn: PropTypes.func,
};
Grid.defaultProps = {
  sortMode: 'single',
  children: null,
  onSelectionChange: null,
  selectedRecords: null,
  sortOrder: null,
  sortField: null,
  rowGroupMode: null,
  groupRowsBy: null,
  showGridlines: false,
  globalFilter: [],
  onValueChange: null,
  lazyParams: null,
  setLazyParamsFn: null,
};

export default React.memo(Grid);
