const awardType = {
  CUSTOMER_SERVICE_AWARD: 1,
  MISSION_TIME_OFF_AWARD: 2,
  CERTIFICATE_OF_APPRECIATION: 3,
  MISSION_LENGTH_OF_SERVICE_AWARD: 5,
  RETIREMENT_RECOGNITION_AWARD: 6,
  MISSION_HONOR_AWARD: 7,
  EAGLE_AWARD: 8,
  SAFE_DRIVER_AWARD: 9,
  GOOD_IDEA_AWARD: 10,
  MISSION_LE_STAFF_EMPLOYEE_AWARD: 11,
  SUPERIOR_HONOR_AWARD: 13,
  getList() {
    return [
      this.CUSTOMER_SERVICE_AWARD,
      this.MISSION_TIME_OFF_AWARD,
      this.CERTIFICATE_OF_APPRECIATION,
      this.MISSION_LENGTH_OF_SERVICE_AWARD,
      this.RETIREMENT_RECOGNITION_AWARD,
      this.MISSION_HONOR_AWARD,
      this.EAGLE_AWARD,
      this.SAFE_DRIVER_AWARD,
      this.GOOD_IDEA_AWARD,
      this.MISSION_LE_STAFF_EMPLOYEE_AWARD,
      this.SUPERIOR_HONOR_AWARD,
    ];
  },
  getName(type) {
    switch (type) {
      case this.CUSTOMER_SERVICE_AWARD:
        return 'Customer Service Award';
      case this.MISSION_TIME_OFF_AWARD:
        return 'Mission Time Off Award';
      case this.CERTIFICATE_OF_APPRECIATION:
        return 'Certificate Of Appreciation';
      case this.MISSION_LENGTH_OF_SERVICE_AWARD:
        return 'Mission Length Of Service Award';
      case this.RETIREMENT_RECOGNITION_AWARD:
        return 'Retirement Recognition Award';
      case this.MISSION_HONOR_AWARD:
        return 'Mission Honor Award';
      case this.EAGLE_AWARD:
        return 'Eagle Award';
      case this.SAFE_DRIVER_AWARD:
        return 'Safe Driver Award';
      case this.GOOD_IDEA_AWARD:
        return 'Good Idea Award';
      case this.MISSION_LE_STAFF_EMPLOYEE_AWARD:
        return 'Mission LE Staff Employee Award';
      case this.SUPERIOR_HONOR_AWARD:
        return 'Superior Honor Award';
      default:
        return 'None';
    }
  },
};

export default awardType;
