import React, { useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { confirmDialog } from 'primereact/confirmdialog';

import { NotificationContext } from 'contexts';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { useVotingForm } from 'hooks/voting';
import { requestStatus, voteType, votingStatus, decisionType } from 'constants/index';
import VotingFormHeader from './VotingFormHeader';
import VotingNominationList from './VotingNominationList';
import VotingNominationDetailsModal from './VotingNominationDetailsModal';
import SignoffToolbar from './SignoffToolbar';
import './VotingForm.css';

function VotingForm() {
  const { showSuccess, showWarn } = useContext(NotificationContext);
  const { checkPermission, checkCyclePermission } = useAuthorization();
  const { goTo } = useNavigationLinks();
  const { mode, navPostId, awardProcessTypeId, navCycleId } = useParams();
  const { formStore, dispatch, helperFunctions } = useVotingForm();
  const { loadListRequest, loadCycleSummaryRequest, currentCycleId, isCycleDefined } = formStore;

  if (mode !== formStore.currentMode) {
    dispatch({ type: 'SET_CURRENT_MODE', data: mode });
    console.log('mode changed:', mode);
  }

  const isPending = useCallback((item) => item.votingStatus === votingStatus.PENDING, []);

  const isTie = useCallback((item) => item.votingStatus === votingStatus.PENDING_TIE, []);

  const onClickVote = (nominationId, vote) => {
    const nomination = helperFunctions.getNominationById(nominationId);
    if (vote === voteType.APPROVED || (nomination.newComment && nomination.newComment.trim())) {
      helperFunctions.sendVote(nominationId, vote);
      showSuccess(
        `${vote === voteType.NONE ? 'Your comment was successfully saved' : 'Your vote was successfully saved'}`
      );
      return;
    }
    if (!nomination.showCommentBox) {
      helperFunctions.onClickShowComments(nominationId);
    }

    if (vote === voteType.RECUSED && nomination.newComment !== '') {
      confirmDialog({
        message:
          'If you recuse yourself, you will no longer see this nomination and you will not be able to change your vote.  Are you sure you would like to recuse?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => helperFunctions.sendVote(nominationId, vote),
      });
    }

    showWarn('This vote requires you to provide a comment');
  };

  const onClickSignOff = async () => {
    if (helperFunctions.getSelected().length === 0) {
      showWarn('You should select at least one nomination.');
      return;
    }

    confirmDialog({
      message: 'Are you sure you want to sign off the selected nominations?',
      header: 'Sign Off Nomination',
      icon: 'pi pi-info-circle',
      acceptClassName: 'ui-button-success',
      accept: async () => {
        if (await helperFunctions.signOffSelected()) {
          showSuccess('The nominations were successfully signed off.');
        }
      },
    });
  };

  const onClickChangeSelected = () => {
    helperFunctions.onChangeModalClick();
  };

  const onClickCheck = (event) => {
    const nominationId = event.value;
    const nominationToCheck = helperFunctions.getNominationById(nominationId);
    event.originalEvent.stopPropagation();

    if (isPending(nominationToCheck)) {
      showWarn('This nomination has not yet been voted by all JCAC members and cannot be selected for endorsement.');
      return false;
    }
    if (isTie(nominationToCheck)) {
      showWarn('The vote on this resulted in a tie. You need to vote and resolve the tie before you can endorse it.');
      return false;
    }
    console.log(nominationToCheck);
    helperFunctions.onClickCheck(nominationId);
    return false;
  };

  const onClickSelect = (flag) => {
    if (flag && formStore.filteredNominations.some((item) => isPending(item))) {
      showWarn('Nominations which are pending or has a tie vote result will not be selected.');
    }
    helperFunctions.onClickSelectAll(flag);
  };

  const onClickDecision = async (nominationId, decision) => {
    const nomination = helperFunctions.getNominationById(nominationId);
    if (
      !helperFunctions.isSignOffMode() &&
      [decisionType.REQUEST_REWRITE, decisionType.DISAPPROVED].includes(decision) &&
      !(nomination.newComment && nomination.newComment.trim().length)
    ) {
      showWarn('This action requires you to provide a comment');
      return;
    }

    let successMessage = 'Your decision was successfully saved.';
    if (decision === decisionType.NONE) {
      successMessage = 'Your comment was successfully saved.';
    }

    if (decision === decisionType.CHANGE) {
      successMessage = 'Your changes are successfully applied.';
    }

    if (decision === decisionType.ENDORSE) {
      successMessage = 'Your changes are applied and the nomination(s) are signed Off.';
    }

    if (await helperFunctions.sendDecision(nominationId, decision)) {
      showSuccess(successMessage);
    }
  };

  useEffect(() => {
    if (!checkPermission('voting') && currentCycleId && !checkCyclePermission('voting', currentCycleId)) {
      goTo('not_authorized');
    }
  }, [checkCyclePermission, checkPermission, currentCycleId, goTo]);

  useEffect(() => {
    const loadListNominations = async (nav) => {
      await helperFunctions.loadNominationsPendingSignoff(nav);
      helperFunctions.loadCurrentUser();
    };
    const loadCycleSummary = async () => {
      await helperFunctions.loadCycleSummary();
    };

    if (!currentCycleId && !isCycleDefined) {
      helperFunctions.setCurrentCycle(0);
    }
    if (currentCycleId && loadListRequest === requestStatus.REQUEST_NOT_INITIATED) {
      let navItem = null;
      if (awardProcessTypeId !== undefined || navCycleId !== undefined || navPostId !== undefined) {
        navItem = {
          postId: Number(navPostId ?? 0),
          awardProcessType: Number(awardProcessTypeId ?? 0),
          cycleId: Number(navCycleId ?? 0),
        };

        helperFunctions.setNavigationFilter(navItem);
      }

      loadListNominations(navItem);
    }
    if (currentCycleId && loadCycleSummaryRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCycleSummary();
    }
  }, [
    currentCycleId,
    helperFunctions,
    isCycleDefined,
    loadCycleSummaryRequest,
    loadListRequest,
    awardProcessTypeId,
    navCycleId,
    navPostId,
  ]);

  if (!currentCycleId && isCycleDefined) {
    return (
      <>
        <h4>{helperFunctions.getTitle()}</h4>
        <div className="container">
          <div className="alert alert-danger d-flex justify-content-center align-items-center" role="alert">
            <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mr-2" />
            There is currently no cycle available. Please contact your post/mission HR Awards Coordinator for more
            information.
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h4>JCAC - {helperFunctions.getTitle()}</h4>
      <VotingFormHeader formStore={formStore} helperFunctions={helperFunctions} />

      {helperFunctions.isSignOffMode() && (
        <SignoffToolbar
          formStore={formStore}
          helperFunctions={helperFunctions}
          onClickSelect={onClickSelect}
          onClickStatusFilter={helperFunctions.onClickStatusFilter}
          onClickTypeFilter={helperFunctions.onClickTypeFilter}
          onClickGroupIndividualFilter={helperFunctions.onClickGroupIndividualFilter}
          onClickSignOff={onClickSignOff}
          onClickChangeSelected={onClickChangeSelected}
        />
      )}

      <VotingNominationList
        formStore={formStore}
        helperFunctions={helperFunctions}
        onClickCheck={(e) => onClickCheck(e)}
        onClickVote={onClickVote}
        onClickDecision={onClickDecision}
        onClickTypeFilter={helperFunctions.onClickTypeFilter}
      />

      <VotingNominationDetailsModal
        isModalOpened={formStore.detailModalOpened}
        nomination={formStore.detailedNomination}
        onClickClose={() => dispatch({ type: 'CLOSE_NOMINATION_DETAILS_MODAL' })}
      />
      <div className="debug mt-3">
        debug Info =&gt; form Mode:
        {mode}
      </div>
    </>
  );
}

export default VotingForm;
