import React, { useReducer, useEffect, useContext, useState } from 'react';
import { requestStatus } from 'constants/index';
import { SessionContext } from 'contexts';
import { ActionButton, Grid, SkeletonTable } from 'components';
import { Column } from 'primereact/column';
import { useNavigationLinks } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { useBureauCycleService } from 'hooks/admin/useBureauCycleService';
import { InputText } from 'primereact/inputtext';

const ACTION_TYPE = {
  START_LOAD_REQUEST: 'START_LOAD_REQUEST',
  LOAD_BUREAUS_LIST: 'LOAD_BUREAUS_LIST',
  UPDATE_BUREAU: 'UPDATE_BUREAU',
};

const initialState = {
  cycles: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.START_LOAD_REQUEST:
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case ACTION_TYPE.LOAD_BUREAUS_LIST:
      return { ...state, cycles: action.data, loadRequest: requestStatus.REQUEST_COMPLETED };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = date.toLocaleString('en-US', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();

  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }

  return `${month} ${day}${suffix}, ${year}`;
}

const closingDateBodyTemplate = (rowData) => {
  return <div>{rowData.closingDate && formatDate(rowData.closingDate)}</div>;
};

const openingDateBodyTemplate = (rowData) => {
  return <div>{rowData.openingDate && formatDate(rowData.openingDate)}</div>;
};

function BureauCycleList() {
  const [store, dispatch] = useReducer(reducer, initialState);
  const { goTo } = useNavigationLinks();
  const bureauCycleService = useBureauCycleService();
  const { sessionStore } = useContext(SessionContext);
  const { currentBureauId, currentBureauName, isBureauUser } = sessionStore;
  const isLoading = store.loadRequest !== requestStatus.REQUEST_COMPLETED;
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    const loadCycleList = async () => {
      dispatch({ type: ACTION_TYPE.START_LOAD_REQUEST });
      const response = await bureauCycleService.getListByBureau(currentBureauId);
      if (response && response.ok) {
        dispatch({ type: ACTION_TYPE.LOAD_BUREAUS_LIST, data: response.data });
      }
    };

    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCycleList();
    }
  }, [bureauCycleService, store.loadRequest, currentBureauId]);

  useEffect(() => {
    if (!isBureauUser) {
      goTo('not_authorized');
    }
  }, [goTo, isBureauUser]);

  const actionBodyTemplate = (rowData) => {
    return (
      <ActionButton
        className="p-button-text p-button-plain px-2"
        onClick={() => goTo('bureau_edit_cycle', { id: rowData.id })}
        icon={['far', 'pencil']}
      />
    );
  };

  return (
    <>
      <h4>{currentBureauName} - Cycles</h4>
      <Row className="mt-3">
        <span className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Filter" />
        </span>
        <Col xs="12">
          <Button
            icon="pi pi-plus"
            label="New Cycle"
            onClick={() => goTo('bureau_new_cycle')}
            className="p-button-primary btn-new-cycle"
          />
        </Col>
      </Row>
      {isLoading && <SkeletonTable colsSize={3} rowsSize={4} />}
      {!isLoading && (
        <Grid
          items={store.cycles}
          dataKey="id"
          sortMode="single"
          sortField="modifiedDate"
          globalFilter={globalFilter}
          sortOrder={-1}
        >
          <Column field="name" header="Name" sortable />
          <Column field="openingDate" header="Opening Date" body={openingDateBodyTemplate} sortable />
          <Column field="closingDate" header="Closing Date" body={closingDateBodyTemplate} sortable />
          <Column header="Edit" body={actionBodyTemplate} />
        </Grid>
      )}
    </>
  );
}

export default BureauCycleList;
