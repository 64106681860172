import useApi from './useApi';

const useDashboardService = () => {
  const api = useApi();

  const getDashboardOptions = async () => api.get('Dashboard/GetUserDashboardOptions');

  return {
    getDashboardOptions,
  };
};

export default useDashboardService;
