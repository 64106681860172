import React, { memo, useReducer } from 'react';
import { requestStatus } from 'constants/index';
import { useDashboardService } from 'hooks';
import uuid from 'react-uuid';
import MissionDashboard from './MissionDashboard';

const initialState = {
  dashboardRequest: requestStatus.REQUEST_NOT_INITIATED,
  result: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_DATA_LOADING':
      return {
        ...state,
        dashboardRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'UPDATE_DASHBOARD_RESULT':
      return {
        ...state,
        dashboardOptions: action.data,
        dashboardRequest: requestStatus.REQUEST_COMPLETED,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function Dashboard() {
  const dashboardService = useDashboardService();
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadDashboard = async () => {
    dispatch({ type: 'START_DATA_LOADING' });

    const response = await dashboardService.getDashboardOptions();

    if (response && response.ok) {
      dispatch({ type: 'UPDATE_DASHBOARD_RESULT', data: response.data });
    }
  };

  if (state.dashboardRequest === requestStatus.REQUEST_NOT_INITIATED) {
    loadDashboard();
  }

  return (
    <>
      <h4>Dashboard</h4>
      {state.dashboardRequest === requestStatus.REQUEST_COMPLETED && state.dashboardOptions.length === 0 ? (
        <div>There are currently no pending actions to take.</div>
      ) : (
        state.dashboardOptions?.map((missionOpt) => (
          <div className="row" key={uuid()}>
            <div className="col-12">
              <MissionDashboard missionDashboardOptions={missionOpt} />
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default memo(Dashboard);
