import React, { useState, useContext, useEffect, useReducer, useCallback } from 'react';
import { flatMap } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Row, Col } from 'reactstrap';
import { Dropdown as PrimeDropDown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faFilter, faQuestion, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { Column } from 'primereact/column';
import {
  requestStatus,
  nominationStatus,
  formOfRecognition,
  approvalStatus,
  processType,
  nominationListMode,
  nominationFormMode,
  nominationAction,
  awardType,
} from 'constants/index';
import { SessionContext, NotificationContext } from 'contexts';
import { useNavigationLinks, useFormatting, useAuthorization } from 'hooks';
import { useCeremonyService } from 'hooks/admin/useCeremonyService';
import { useBureauService } from 'hooks/admin/useBureauService';
import { useNominationService } from 'hooks/nomination';
import { Grid, SkeletonTable, TypeBadge } from 'components';
import { formatDistanceToUtcNow } from 'utils/dateHelpers';
import SendToOPSModal from './SendToOPSModal';
import AssignCeremonyModal from './AssignCeremonyModal';
import PrintFormModal from '../NominationForm/PrintFormModal';
import './NominationsList.css';
import SupervisorEmailModal from './SupervisorEmailModal';
import FiscalStripModal from './FiscalStripModal';

const ASSIGN_CEREMONY_MODE = 1;
const GENERATE_SCRIPT_MODE = 0;

const cleanLazyParams = {
  first: 0,
  rows: 10,
  pageNumber: 0,
  sortField: null,
  sortOrder: null,
  requestStatus: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOMINATIONS': {
      return {
        ...state,
        totalRecords: action.data.list.totalCount,
        loadedNominations: action.data.list.items,
        nominationStatusOptions: action.data.statusList,
        dataFetching: requestStatus.REQUEST_COMPLETED,
      };
    }

    case 'CLEAR_FILTERS':
      return {
        ...state,
        filterTerm: '',
        cycleIds: [0],
        postIds: [0],
        processTypeFilter: 0,
        statusIds: [0],
        awardTypeIds: [0],
        loadedNominations: [],
        dataFetching: requestStatus.REQUEST_NOT_INITIATED,
      };

    case 'FORCE_REFRESH':
      return {
        ...state,
        selectedRecords: [],
        dataFetching: requestStatus.REQUEST_NOT_INITIATED,
      };

    case 'SET_FILTER_TERM':
      return { ...state, filterTerm: action.data };

    case 'UPDATE_PROCESS_FILTER':
      return { ...state, processTypeFilter: action.data };

    case 'RESET_FILTERED_NOMINATIONS':
      return {
        ...state,
        filterTerm: '',
        filteredNominations: [],
        dataFetching: requestStatus.REQUEST_NOT_INITIATED,
      };

    case 'SET_DATA_FETCHING':
      return {
        ...state,
        dataFetching: action.data,
      };

    case 'SET_CYCLES':
      return {
        ...state,
        cycleIds: action.data,
        loadedNominations: [],
      };

    case 'SET_SELECTED_NOMINATION_STATUSES': {
      let selectedStatuses = action.data;
      if (action.data.length === 0) {
        selectedStatuses = [0];
      }
      return {
        ...state,
        statusIds: selectedStatuses,
        loadedNominations: [],
      };
    }

    case 'SET_CEREMONY':
      return {
        ...state,
        ceremonyId: action.data,
        ceremonyName: state.loadedCeremonies.find((x) => x.id === action.data)?.name,
        loadedNominations: [],
        selectedRecords: [],
      };

    case 'SET_MISSIONS':
      return {
        ...state,
        missionIds: action.data,
      };

    case 'SET_POSTS':
      return {
        ...state,
        postIds: action.data,
        loadedNominations: [],
      };

    case 'SET_AWARDTYPES': {
      return {
        ...state,
        awardTypeIds: action.data,
      };
    }

    case 'RESET_FILTERS': {
      return {
        ...state,
        cycleIds: [0],
        postIds: [0],
        missionids: [0],
        statusIds: [0],
        awardTypeIds: [0],
        processTypeFilter: 0,
        loadedNominations: [],
        filteredNominations: [],
        dataFetching: requestStatus.REQUEST_NOT_INITIATED,
      };
    }

    case 'UPDATE_SELECTED_RECORDS':
      return { ...state, selectedRecords: action.data, fiscalStripUpdated: false };

    case 'OPEN_SENDTOOPS_MODAL':
      return {
        ...state,
        isSendToOPSModalOpened: true,
        sendToOPSModalMode: action.data.statusMode,
        sendToOPSModalList: action.data.listToProcess,
        nominationAction: action.data.nominationAction,
      };

    case 'OPEN_ASSIGNCEREMONY_MODAL':
      return {
        ...state,
        isAssignCeremonyModalOpened: true,
        assignCeremonyModalList: action.data.listToProcess,
      };

    case 'CLOSE_SENDTOOPS_MODAL':
      return {
        ...state,
        isSendToOPSModalOpened: false,
      };

    case 'CLOSE_ASSIGNCEREMONY_MODAL':
      return {
        ...state,
        isAssignCeremonyModalOpened: false,
      };

    case 'OPEN_PRINT_FORM_MODAL':
      return {
        ...state,
        isPrintFormModalOpened: true,
        currentNominationId: action.data.currentNominationId,
        awardProcessType: action.data.awardProcessType,
      };

    case 'CLOSE_PRINT_FORM_MODAL':
      return {
        ...state,
        isPrintFormModalOpened: false,
      };

    case 'OPEN_SUPERVISOR_EMAIL_MODAL':
      return {
        ...state,
        isSupervisorEmailModalOpened: true,
        nominationId: action.data.nominationId,
        supervisorId: action.data.supervisorId,
        supervisorName: action.data.supervisorName,
      };

    case 'CLOSE_SUPERVISOR_EMAIL_MODAL':
      return {
        ...state,
        isSupervisorEmailModalOpened: false,
      };

    case 'OPEN_FISCAL_STRIP_MODAL':
      return {
        ...state,
        isFiscalStripModalOpen: true,
        fiscalData: {
          type: 'nomination',
          nominationIds: action.data.nominationIds,
          fiscalStrip: null,
        },
      };

    case 'CLOSE_FISCAL_STRIP_MODAL':
      return {
        ...state,
        isFiscalStripModalOpen: false,
      };

    case 'LOAD_CEREMONIES':
      return {
        ...state,
        loadedCeremonies: action.data.ceremonies,
        ceremonyName: action.data.ceremonyName,
        dataFetching: requestStatus.REQUEST_COMPLETED,
      };

    case 'SET_MISSION_OPTIONS':
      return {
        ...state,
        missionOptions: action.data,
      };

    case 'CHANGE_STATUS':
      return {
        ...state,
        loadedNominations: state.loadedNominations.map((x) => {
          if (state.selectedRecords.map((_) => _.id).includes(x.id)) {
            return {
              ...x,
              nominationStatus: nominationStatus.PENDING_SEND_TO_OPS,
              nominationStatusName: 'Pending Sending Data to OPS',
            };
          }
          return x;
        }),

        filteredNominations: state.filteredNominations.map((x) => {
          if (state.selectedRecords.map((_) => _.id).includes(x.id)) {
            return {
              ...x,
              nominationStatus: nominationStatus.PENDING_SEND_TO_OPS,
              nominationStatusName: 'Pending Sending Data to OPS',
            };
          }
          return x;
        }),
        selectedRecords: state.selectedRecords.map((x) => {
          if (x.nominationStatus === nominationStatus.PENDING_EFFECTIVE_DATE) {
            return {
              ...x,
              nominationStatus: nominationStatus.PENDING_SEND_TO_OPS,
              nominationStatusName: 'Pending Sending Data to OPS',
            };
          }
          return {
            ...x,
            nominationStatus: nominationStatus.COMPLETED,
            nominationStatusName: 'nomination completed',
          };
        }),
      };

    case 'REMOVE_SELECTED_NOMINATIONS':
      return {
        ...state,
        loadedNominations: state.loadedNominations.filter((x) => !action.data?.includes(x.id)),
        filteredNominations: state.filteredNominations.filter((x) => !action.data?.includes(x.id)),
      };

    case 'ASSIGN_FISCAL_STRIP_TO_SELECTED_NOMINATIONS':
      return {
        ...state,
        isFiscalStripModalOpen: false,
        fiscalStripUpdated: true,
        selectedRecords: state.selectedRecords.map((nomination) => {
          return {
            ...nomination,
            isFiscalStripAssignedToAllNominees: true,
            nominationEmployees: nomination.nominationEmployees.map((nominee) => {
              return {
                ...nominee,
                fiscalStrip: action.data,
              };
            }),
          };
        }),
        filteredNominations: state.filteredNominations.map((nomination) => {
          if (state.selectedRecords.map((_) => _.id).includes(nomination.id)) {
            return {
              ...nomination,
              isFiscalStripAssignedToAllNominees: true,
              nominationEmployees: nomination.nominationEmployees.map((nominee) => {
                return {
                  ...nominee,
                  fiscalStrip: action.data,
                };
              }),
            };
          }
          return nomination;
        }),
        loadedNominations: state.loadedNominations.map((nomination) => {
          if (state.selectedRecords.map((_) => _.id).includes(nomination.id)) {
            return {
              ...nomination,
              isFiscalStripAssignedToAllNominees: true,
              nominationEmployees: nomination.nominationEmployees.map((nominee) => {
                return {
                  ...nominee,
                  fiscalStrip: action.data,
                };
              }),
            };
          }
          return nomination;
        }),
      };

    case 'ASSIGN_FISCAL_STRIP':
      return {
        ...state,
        isFiscalStripModalOpen: false,
        loadedNominations: state.loadedNominations.map((x) => {
          if (state.selectedRecords.map((_) => _.id).includes(x.id)) {
            return {
              ...x,
              isFiscalStripAssignedToAllNominees: true,
            };
          }
          return x;
        }),

        filteredNominations: state.loadedNominations.map((x) => {
          if (state.selectedRecords.map((_) => _.id).includes(x.id)) {
            return {
              ...x,
              isFiscalStripAssignedToAllNominees: true,
            };
          }
          return x;
        }),
        selectedRecords: state.selectedRecords.map((x) => {
          return {
            ...x,
            isFiscalStripAssignedToAllNominees: true,
          };
        }),
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function NominationsList() {
  const { sessionStore } = useContext(SessionContext);
  const service = useNominationService();
  const bureauService = useBureauService();
  const { getURL } = useNavigationLinks();
  const history = useHistory();
  const { mode, navPostId, awardProcessTypeId, navNominationStatus, navCycleId } = useParams();
  const { formatCycle } = useFormatting();
  const { getAvailableCyclesForFeature, getAvailableBureauCyclesForFeature, checkRole, checkPermission } =
    useAuthorization();
  const { goTo } = useNavigationLinks();
  const [ceremonyMode, setceremonyMode] = useState(1);
  const [fiscalStripValue, setFiscalStripValue] = useState(null);
  const { employeeId } = sessionStore.employeeUser;
  const { currentMissionId, currentMissionName, isBureauEnabled, isBureauUser, currentBureauId } = sessionStore;
  const searchParams = new URLSearchParams(window.location.search);
  const nomineeName = searchParams.get('nomineeName');
  const ceremonyService = useCeremonyService();
  const { showSuccess, showError } = useContext(NotificationContext);
  const [enableFMO, setEnableFMO] = useState(false);
  const [lazyParams, setLazyParams] = useState(cleanLazyParams);

  const urlMode = Boolean(Number(navPostId));

  const CYCLE_STORAGE_NAME = 'nl-cycleIds';
  const POST_STORAGE_NAME = 'nl-postId';
  const AWARDPROCESS_STORAGE_NAME = 'nl-awardProcessTypeId';
  const NOM_STATUS_STORAGE_NAME = 'nl-nominationStatus';
  const MISSION_STORAGE_NAME = 'nl-missionIds';
  const AWARD_TYPE_STORAGE_NAME = 'nl_awardtypeIds';
  const MODE_STORAGE_NAME = 'nl-mode';

  const getIdInit = (navInput, storageName) => {
    if (Number.isNaN(Number(navInput))) {
      const storedVal = localStorage.getItem(storageName);
      if (Number.isNaN(Number(storedVal))) {
        return 0;
      }
      return Number(storedVal);
    }
    return Number(navInput);
  };

  const getIdInitArray = (navInput, storageName) => {
    if (Number.isNaN(Number(navInput))) {
      const storedVal = localStorage.getItem(storageName);
      try {
        const parsed = JSON.parse(storedVal);
        if (Array.isArray(parsed)) {
          return parsed;
        }
        return [0];
      } catch (e) {
        return [0];
      }
    }
    return [Number(navInput)];
  };

  const isSendToOPSMode = useCallback(() => mode === 'SendToOPS', [mode]);
  const isPendingReviewMode = useCallback(() => mode === 'PendingReview', [mode]);
  const isExternalApprovalMode = useCallback(() => mode === 'ExternalApproval', [mode]);
  const isPendingUSDHReviewMode = useCallback(() => mode === 'PendingUSDHApproval', [mode]);
  const isOngoingMode = useCallback(() => mode === 'OngoingNominations', [mode]);
  const isCompletedMode = useCallback(() => mode === 'Completed', [mode]);
  const isPendingFinancialInfoMode = useCallback(() => mode === 'PendingFinancialInfo', [mode]);
  const isCeremonyAssignmentMode = useCallback(() => mode === 'CeremonyAssignment', [mode]);
  const isBureauPendingReviewMode = useCallback(() => mode === 'BureauPendingReview', [mode]);
  const isBureauOngoingMode = useCallback(() => mode === 'BureauOngoing', [mode]);
  const isBureauDecisionMode = useCallback(() => mode === 'BureauDecision', [mode]);
  const isDisapprovedMode = useCallback(() => mode === 'Disapproved', [mode]);
  const isMyNominationsMode = useCallback(() => mode === 'MySubmissions', [mode]);
  const isMyHistoryMode = useCallback(() => mode === 'MyNominations', [mode]);
  const isSupervisorOngoingMode = useCallback(() => mode === 'SupervisorOngoing', [mode]);

  const showMissionDropDown = isBureauPendingReviewMode() || isBureauOngoingMode();

  const getAwardTypeOptions = () => {
    const list = awardType.getList().map((item) => ({ value: item, label: awardType.getName(item) }));
    list.unshift({ value: 0, label: 'All' });
    return list;
  };

  const getPostOptions = () => {
    let list = [];
    if (!isBureauUser) {
      list = Object.values(sessionStore.missionPosts).map((item) => ({ value: item.id, label: item.name }));
    }
    list.unshift({ value: 0, label: 'All' });
    return list;
  };

  const initialState = {
    filterTerm: '',
    loadedNominations: [],
    loadedCeremonies: [],
    filteredNominations: [],
    totalRecords: 0,
    dataFetching: requestStatus.REQUEST_NOT_INITIATED,
    cycleIds: getIdInitArray(navCycleId, CYCLE_STORAGE_NAME),
    bureauCycleId: 0,
    ceremonyId: 0,
    postIds: isBureauUser ? [0] : getIdInitArray(navPostId, POST_STORAGE_NAME),
    postOptions: getPostOptions(),
    bureauId: 0,
    missionIds: showMissionDropDown ? getIdInitArray({}, MISSION_STORAGE_NAME) : [0],
    missionOptions: [],
    awardTypeIds: isBureauUser ? [0] : getIdInitArray({}, AWARD_TYPE_STORAGE_NAME),
    awardTypeOptions: getAwardTypeOptions(),
    missionName: '',
    postName: '',
    formOfRecognition: formOfRecognition.CASH_ONLY,
    ceremonyName: '',
    isCeremonySkipped: false,
    isSendToOPSModalOpened: false,
    isAssignCeremonyModalOpened: false,
    sendToOPSModalMode: 0,
    sendToOPSModalList: [],
    assignCeremonyModalList: [],
    selectedRecords: [],
    processTypeFilter: isBureauUser ? 0 : getIdInit(awardProcessTypeId, AWARDPROCESS_STORAGE_NAME),
    isPrintFormModalOpened: false,
    currentNominationId: null,
    awardProcessType: null,
    nominationId: 0,
    supervisorId: 0,
    supervisorName: null,
    fiscalData: null,
    nominationAction: nominationAction.NONE,
    nominationStatuses: [0],
    statusIds: getIdInitArray(navNominationStatus, NOM_STATUS_STORAGE_NAME),
    nominationStatusOptions: [],
  };

  const [formStore, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (urlMode) {
      localStorage.setItem(MISSION_STORAGE_NAME, JSON.stringify([0]));
      localStorage.setItem(AWARD_TYPE_STORAGE_NAME, JSON.stringify([0]));
    } else if (mode !== localStorage.getItem(MODE_STORAGE_NAME)) {
      localStorage.setItem(CYCLE_STORAGE_NAME, JSON.stringify([0]));
      localStorage.setItem(POST_STORAGE_NAME, JSON.stringify([0]));
      localStorage.setItem(AWARDPROCESS_STORAGE_NAME, 0);
      localStorage.setItem(NOM_STATUS_STORAGE_NAME, JSON.stringify([0]));
      localStorage.setItem(MISSION_STORAGE_NAME, JSON.stringify([0]));
      localStorage.setItem(AWARD_TYPE_STORAGE_NAME, JSON.stringify([0]));
      dispatch({ type: 'RESET_FILTERS' });
    }
    localStorage.setItem(MODE_STORAGE_NAME, mode);
  }, [mode, urlMode]);

  const hasItemSelected = (filterStatus) =>
    formStore.selectedRecords.some((item) => item.nominationStatus === filterStatus);
  const hasAllItemSelected = (filterStatus) =>
    formStore.selectedRecords.length === 0
      ? false
      : formStore.selectedRecords.every((item) => item.nominationStatus === filterStatus);

  const closeSendToOPSModal = (forceRefreshList) => {
    dispatch({ type: 'CLOSE_SENDTOOPS_MODAL' });
    if (forceRefreshList) {
      dispatch({ type: 'FORCE_REFRESH' });
    }
  };

  const closeAssignCeremonySModal = (forceRefreshList) => {
    dispatch({ type: 'CLOSE_ASSIGNCEREMONY_MODAL' });
    if (forceRefreshList) {
      dispatch({ type: 'FORCE_REFRESH' });
    }
  };

  const openSendToOPSModal = (status, isExcludeFromOPS, action) => {
    dispatch({
      type: 'OPEN_SENDTOOPS_MODAL',
      data: {
        statusMode: status,
        nominationAction: action,
        listToProcess: isExcludeFromOPS
          ? formStore.selectedRecords
          : formStore.selectedRecords.filter((item) => item.nominationStatus === status),
      },
    });
  };

  const openAssignCeremonyModal = (status) => {
    setceremonyMode(status);
    dispatch({
      type: 'OPEN_ASSIGNCEREMONY_MODAL',
      data: {
        listToProcess: formStore.selectedRecords,
      },
    });
  };

  const onClickClosePrintModal = () => {
    dispatch({ type: 'CLOSE_PRINT_FORM_MODAL' });
  };

  const openPrintFormModal = (nominationId, awardProcessType) => {
    dispatch({
      type: 'OPEN_PRINT_FORM_MODAL',
      data: {
        currentNominationId: nominationId,
        awardProcessType,
      },
    });
  };

  const closeSupervisorEmailModal = () => {
    dispatch({ type: 'CLOSE_SUPERVISOR_EMAIL_MODAL' });
  };

  const openFiscalStripModal = (nominationIds) => {
    dispatch({
      type: 'OPEN_FISCAL_STRIP_MODAL',
      data: {
        nominationIds,
      },
    });
  };

  const closeFiscalStripModal = () => {
    dispatch({ type: 'CLOSE_FISCAL_STRIP_MODAL' });
  };

  const openSupervisorEmailModal = (nominationId, usdhSupervisorId, usdhSupervisorName) => {
    dispatch({
      type: 'OPEN_SUPERVISOR_EMAIL_MODAL',
      data: {
        nominationId,
        supervisorId: usdhSupervisorId,
        supervisorName: usdhSupervisorName,
      },
    });
  };

  // check for checkCyclePermission
  const getCycles = useCallback(
    () =>
      getAvailableCyclesForFeature('fm_input_fiscaldata').map((item) => ({
        value: item.id,
        label: formatCycle(item),
      })),
    [getAvailableCyclesForFeature, formatCycle]
  );

  const getBureauCycles = useCallback(
    () =>
      getAvailableBureauCyclesForFeature('bureau_coordinator').map((item) => ({
        value: item.id,
        label: formatCycle(item),
      })),
    [getAvailableBureauCyclesForFeature, formatCycle]
  );

  const setEffectiveDate = (isNominationCompleted) => {
    if (isNominationCompleted === true) {
      dispatch({ type: 'REMOVE_SELECTED_NOMINATIONS' });
      dispatch({ type: 'FORCE_REFRESH' });
    } else {
      dispatch({ type: 'CHANGE_STATUS' });
    }
  };
  const getCeremonies = useCallback(async () => {
    const response = await ceremonyService.getListByMission(currentMissionId);
    if (response && response.ok) {
      dispatch({
        type: 'LOAD_CEREMONIES',
        data: {
          ceremonies: response.data,
          ceremonyId: response.data[0].id,
          ceremonyName: response.data[0].name,
        },
      });
    }
    return null;
  }, [ceremonyService, currentMissionId]);

  const getTitle = useCallback(() => {
    switch (mode) {
      case 'MyNominations':
        return 'Nominations I was nominated for';
      case 'MySubmissions':
        return 'Nominator - My Submitted Nominations';
      case 'SupervisorApproval':
        return 'Supervisor - Pending Approvals';
      case 'PendingReview':
        return 'HR - Pending Review';
      case 'PendingUSDHApproval':
        return 'HR - Pending USDH Approval';
      case 'PendingFinancialInfo':
        return 'Finance - Pending Fiscal Data';
      case 'SendToOPS':
        return 'HR - Approved Nominations Pending Sending To OPS';
      case 'OngoingNominations':
        return 'HR - Ongoing Nominations';
      case 'ExternalApproval':
        return 'HR - Pending External Approval';
      case 'Disapproved':
        return 'HR - Disapproved Nominations';
      case 'Completed':
        return 'HR - Completed Nominations';
      case 'LocalSupervisor':
        return 'LES Supervisor';
      case 'SupervisorOngoing':
        return 'Supervisee Ongoing';
      case 'CeremonyAssignment':
        return 'Ceremony Assignment';
      case 'BureauPendingReview':
        return 'Bureau Coordinator Review';
      case 'BureauOngoing':
        return 'Bureau Coordinator Ongoing';
      case 'BureauDecision':
        return 'Bureau Decision';
      default:
        return 'Title Not Defined';
    }
  }, [mode]);

  const getLink = useCallback(
    (id, status) => {
      switch (mode) {
        case 'MyNominations': // as nominee
          return getURL('view_nomination_form', { id });
        case 'MySubmissions':
          if (status && [nominationStatus.DRAFT, nominationStatus.PENDING_REWRITE].includes(status))
            return getURL('edit_nomination_form', { id });
          return getURL('view_nomination_form', { id });
        case 'SupervisorApproval':
          return getURL('approve_nomination_form', { id });
        case 'SupervisorOngoing':
          return getURL('view_nomination_form', { id });
        case 'PendingReview':
          return getURL('pending_review_form', { id });
        case 'PendingUSDHApproval':
          return getURL('approve_nomination_form', { id });
        case 'SendToOPS':
          return getURL('send_to_ops_form', { id });
        case 'PendingFinancialInfo':
          return getURL('finance_analyst_form', { id });
        case 'OngoingNominations':
          return getURL('ongoing_nomination_form', { id });
        case 'ExternalApproval':
          return getURL('external_approval_form', { id });
        case 'Disapproved':
          return getURL('disapproved_form', { id });
        case 'Completed':
          return getURL('completed_form', { id });
        case 'CeremonyAssignment':
          return getURL('completed_form', { id });
        case 'LocalSupervisor':
          return getURL('les_view_form', { id });
        case 'BureauPendingReview':
          return getURL('pending_bureau_review_form', { id });
        case 'BureauOngoing':
          return getURL('ongoing_bureau_nomination_form', { id });
        case 'BureauDecision':
          return getURL('external_approval_form', { id });
        default:
          return getURL('home');
      }
    },
    [getURL, mode]
  );

  useEffect(() => {
    const applyRequestData = (listMode) => {
      const requestData = {
        userEmployeeId: employeeId,
        missionId: currentMissionId,
        missionIds: formStore.missionIds,
        cycleIds: formStore.cycleIds,
        postIds: formStore.postIds,
        awardTypeIds: formStore.awardTypeIds,
        awardProcessType: formStore.processTypeFilter ?? processType.NONE,
        filterTerm: formStore.filterTerm,
        isBureauUser,
        bureauId: currentBureauId,
        pageNumber: lazyParams.pageNumber,
        pageSize: lazyParams.rows,
        sortField: lazyParams.sortField,
        sortOrder: lazyParams.sortOrder,
        mode: listMode,
        statusIds: formStore.statusIds,
      };

      if (listMode === 'MyNominations') {
        // my history in UI
        requestData.nomineeId = employeeId;
        requestData.missionId = currentMissionId;
      }

      if (listMode === 'MySubmissions') {
        // my nominations in UI
        requestData.nominatorId = employeeId;
        requestData.missionId = currentMissionId;
        requestData.awardProcessType = formStore.processTypeFilter;
        requestData.cycleIds = formStore.cycleIds;
      }

      if (listMode === 'SupervisorApproval') {
        requestData.supervisorId = employeeId;
      }

      if (listMode === 'LocalSupervisor') {
        requestData.localSupervisorId = employeeId;
      }
      if (listMode === 'SupervisorOngoing') {
        if (Object.values(sessionStore.userRoles).includes('LocalSupervisor')) {
          requestData.localSupervisorId = employeeId;
        } else requestData.supervisorId = employeeId;
        requestData.listMode = nominationListMode.SUPERVISOR_ONGOING;
      }

      if (listMode === 'PendingReview') {
        requestData.awardProcessType = formStore.processTypeFilter;
      }

      if (isPendingFinancialInfoMode()) {
        requestData.listMode = nominationListMode.FINANCIAL_INFORMATION;
      }
      if (isCeremonyAssignmentMode()) {
        requestData.listMode = nominationListMode.CEREMONY_ASSIGNMENT;
        requestData.ceremonyId = formStore.ceremonyId;
      }
      if (listMode === 'Completed') {
        requestData.formMode = nominationFormMode.COMPLETED;
      }

      if (listMode === 'BureauPendingReview') {
        if (!isBureauUser) {
          goTo('not_authorized');
        }
        requestData.formMode = nominationFormMode.BUREAU_REVIEW;
        requestData.cycleIds = [0];
      }

      if (listMode === 'BureauOngoing') {
        if (!isBureauUser) {
          goTo('not_authorized');
        }
      }

      return requestData;
    };

    async function loadData() {
      dispatch({ type: 'SET_DATA_FETCHING', data: requestStatus.REQUEST_IN_PROGRESS });
      setLazyParams((prev) => ({ ...prev, requestStatus: requestStatus.REQUEST_IN_PROGRESS }));
      if (isBureauUser && formStore.missionOptions.length === 0) {
        const missionsResponse = await bureauService.getBureauMissions(currentBureauId);
        if (missionsResponse && missionsResponse.ok) {
          const missionOptions = Object.values(missionsResponse.data).map((item) => ({
            value: item.id,
            label: item.name,
          }));
          missionOptions.unshift({ value: 0, label: 'All' });
          dispatch({ type: 'SET_MISSION_OPTIONS', data: missionOptions });
        }
      }
      const request = applyRequestData(mode);
      const response = await service.getList(request);
      if (response && response.ok) {
        dispatch({ type: 'SET_NOMINATIONS', data: response.data });
        setLazyParams((prev) => ({ ...prev, requestStatus: requestStatus.REQUEST_COMPLETED }));
        localStorage.setItem(
          'loadedNominations',
          JSON.stringify(
            response.data.items?.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)).map((x) => x.id)
          )
        );
        if (nomineeName) {
          dispatch({ type: 'SET_FILTER_TERM', data: nomineeName });
          setLazyParams((prev) => ({ ...prev, pageNumber: 0, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
        }
      }
    }

    if (
      (formStore.dataFetching === requestStatus.REQUEST_NOT_INITIATED ||
        lazyParams.requestStatus === requestStatus.REQUEST_NOT_INITIATED) &&
      employeeId
    ) {
      loadData();
    }
  }, [
    isBureauUser,
    employeeId,
    formStore.cycleIds,
    formStore.ceremonyId,
    formStore.dataFetching,
    formStore.filterTerm,
    isPendingFinancialInfoMode,
    currentMissionId,
    mode,
    nomineeName,
    service,
    isCeremonyAssignmentMode,
    sessionStore,
    formStore.postIds,
    formStore.processTypeFilter,
    goTo,
    formStore.statusIds,
    currentBureauId,
    formStore.awardTypeIds,
    formStore.missionIds,
    formStore.missionOptions,
    bureauService,
    lazyParams,
  ]);

  if (isCeremonyAssignmentMode() && formStore.dataFetching === requestStatus.REQUEST_NOT_INITIATED) {
    getCeremonies();
  }

  const onChangeMultiCycle = (cycleIds) => {
    let newCycleIds = cycleIds;
    if (
      (cycleIds.includes(0) && !formStore.cycleIds.includes(0)) ||
      (cycleIds.length === 0 && !formStore.cycleIds.includes(0)) ||
      (cycleIds.length === 0 && formStore.cycleIds.includes(0))
    ) {
      newCycleIds = [0];
    } else if (cycleIds.length > 1 && formStore.cycleIds.includes(0)) {
      newCycleIds = cycleIds.filter((num) => num !== 0);
    }
    dispatch({ type: 'SET_CYCLES', data: newCycleIds });
    setLazyParams((prev) => ({ ...prev, pageNumber: 0, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
  };

  const onChangeCeremony = (ceremonyId) => {
    dispatch({ type: 'SET_CEREMONY', data: ceremonyId });
    setLazyParams((prev) => ({ ...prev, pageNumber: 0, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
  };

  const onChangeMultiNominationStatus = (nominationStatusIds) => {
    let newNominationStatusIds = nominationStatusIds;
    if (
      (nominationStatusIds.includes(0) && !formStore.statusIds.includes(0)) ||
      (nominationStatusIds.length === 0 && !formStore.statusIds.includes(0)) ||
      (nominationStatusIds.length === 0 && formStore.statusIds.includes(0))
    ) {
      newNominationStatusIds = [0];
    } else if (nominationStatusIds.length > 1 && formStore.statusIds.includes(0)) {
      newNominationStatusIds = nominationStatusIds.filter((num) => num !== 0);
    }
    dispatch({ type: 'SET_SELECTED_NOMINATION_STATUSES', data: newNominationStatusIds });
    setLazyParams((prev) => ({ ...prev, pageNumber: 0, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
  };

  const onChangeMultiAwardType = (awardTypeIds) => {
    let newAwardTypeIds = awardTypeIds;
    if (
      (awardTypeIds.includes(0) && !formStore.awardTypeIds.includes(0)) ||
      (awardTypeIds.length === 0 && !formStore.awardTypeIds.includes(0)) ||
      (awardTypeIds.length === 0 && formStore.awardTypeIds.includes(0))
    ) {
      newAwardTypeIds = [0];
    } else if (awardTypeIds.length > 1 && formStore.awardTypeIds.includes(0)) {
      newAwardTypeIds = awardTypeIds.filter((num) => num !== 0);
    }
    dispatch({ type: 'SET_AWARDTYPES', data: newAwardTypeIds });
    setLazyParams((prev) => ({ ...prev, pageNumber: 0, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
  };

  const onChangeProcessTypeFilter = (processTypeOption) => {
    dispatch({ type: 'SET_DATA_FETCHING', data: requestStatus.REQUEST_NOT_INITIATED });
    dispatch({ type: 'UPDATE_PROCESS_FILTER', data: processTypeOption });
  };

  const onChangeMultiPost = (postIds) => {
    let newPostIds = postIds;
    if (
      (postIds.includes(0) && !formStore.postIds.includes(0)) ||
      (postIds.length === 0 && !formStore.postIds.includes(0)) ||
      (postIds.length === 0 && formStore.postIds.includes(0))
    ) {
      newPostIds = [0];
    } else if (postIds.length > 1 && formStore.postIds.includes(0)) {
      newPostIds = postIds.filter((num) => num !== 0);
    }
    dispatch({ type: 'SET_POSTS', data: newPostIds });
    setLazyParams((prev) => ({ ...prev, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
  };

  const onChangeMultiMission = (missionIds) => {
    let newMissionIds = missionIds;
    if (
      (missionIds.includes(0) && !formStore.missionIds.includes(0)) ||
      (missionIds.length === 0 && !formStore.missionIds.includes(0)) ||
      (missionIds.length === 0 && formStore.missionIds.includes(0))
    ) {
      newMissionIds = [0];
    } else if (missionIds.length > 1 && formStore.missionIds.includes(0)) {
      newMissionIds = missionIds.filter((num) => num !== 0);
    }
    dispatch({ type: 'SET_MISSIONS', data: newMissionIds });
    setLazyParams((prev) => ({ ...prev, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
  };

  const isCheckboxEnabled =
    isSendToOPSMode() || isCeremonyAssignmentMode() || isPendingFinancialInfoMode() || isExternalApprovalMode();

  useEffect(() => {
    const hrPageModes = [
      'PendingReview',
      'PendingUSDHApproval',
      'OngoingNominations',
      'SendToOPS',
      'ExternalApproval',
      'CeremonyAssignment',
      'Disapproved',
      'Completed',
      'BureauDecision',
    ];

    const bureauPages = ['BureauPendingReview', 'BureauOngoing'];

    const supervisorPages = ['SupervisorOngoing', 'LocalSupervisor', 'SupervisorApproval'];
    let doesUserHaveAccess = false;

    if (mode === 'MySubmissions' || mode === 'MyNominations') {
      doesUserHaveAccess = true;
    }

    if (mode === 'PendingFinancialInfo') {
      doesUserHaveAccess = checkRole('fm_input_fiscaldata');
    }

    if (supervisorPages.includes(mode)) {
      doesUserHaveAccess = checkPermission('supervisors');
    }

    if (hrPageModes.includes(mode)) {
      doesUserHaveAccess = checkRole('hr_officer');
    }

    if (bureauPages.includes(mode)) {
      doesUserHaveAccess = true;
    }

    if (!doesUserHaveAccess) {
      goTo('not_authorized');
    }
  }, [isPendingFinancialInfoMode, mode, checkRole, checkPermission, goTo]);

  function renderMultiCycleDropDown() {
    if (isPendingFinancialInfoMode()) {
      const doesUserHaveFARole = checkRole('fm_input_fiscaldata');
      if (!doesUserHaveFARole) {
        goTo('not_authorized');
      }
    }

    if (isBureauPendingReviewMode() || isBureauOngoingMode()) {
      const doesUserHaveBureauRole = checkRole('bureau_coordinator');
      if (!doesUserHaveBureauRole) {
        goTo('not_authorized');
      }
    }

    const cyclesList = isBureauOngoingMode() ? getBureauCycles() : getCycles();
    cyclesList.unshift({ value: -1, label: 'Not Assigned' });
    cyclesList.unshift({ value: 0, label: 'All' });

    return (
      <MultiSelect
        value={formStore.cycleIds}
        options={cyclesList}
        onChange={(e) => onChangeMultiCycle(e.value)}
        className="cycle-multi-dropdown-filter"
        showSelectAll={false}
        disabled={formStore.processTypeFilter === processType.ANYTIME}
      />
    );
  }

  function renderCeremonyDropdown() {
    const ceremonyList = Object.values(formStore.loadedCeremonies).map((item) => ({
      value: item.id,
      label: item.name,
    }));
    ceremonyList.unshift({ value: -1, label: '(Skipped Nominations)' });
    ceremonyList.unshift({ value: 0, label: '(Unassigned Nominations)' });

    return (
      <PrimeDropDown
        value={formStore.ceremonyId}
        options={ceremonyList}
        onChange={(e) => onChangeCeremony(e.value)}
        className="cycle-dropdown-filter"
        disabled={ceremonyList && ceremonyList.length < 2}
      />
    );
  }

  const setSelectedRecords = (selectedRecords) => {
    dispatch({
      type: 'UPDATE_SELECTED_RECORDS',
      data: selectedRecords,
    });
  };

  useEffect(() => {
    const allFiscalStripsForSelectedNominations = flatMap(
      formStore.selectedRecords.map((x) =>
        x.nominationEmployees.filter((_) => _.approvalStatus === approvalStatus.APPROVED).map((y) => y.fiscalStrip)
      )
    );
    const isAllFiscalStripsSame = allFiscalStripsForSelectedNominations.every(
      (val) => val === allFiscalStripsForSelectedNominations[0]
    );
    setFiscalStripValue(isAllFiscalStripsSame ? allFiscalStripsForSelectedNominations[0] : null);
  }, [formStore.selectedRecords]);

  const onUpdateFiscalStrip = async (fiscalStripData) => {
    const temp = Object.values(fiscalStripData.data);
    temp.forEach((v, i) => {
      if (!temp[i]) {
        temp[i] = '';
      }
    });
    const assembledFiscalStrip = temp.join('/');
    setFiscalStripValue(assembledFiscalStrip);

    dispatch({
      type: 'ASSIGN_FISCAL_STRIP_TO_SELECTED_NOMINATIONS',
      data: assembledFiscalStrip,
    });
  };

  const onSaveFiscalStrip = async () => {
    // get selected rows
    const dataToSave = {
      fiscalData: fiscalStripValue.trim(),
      nominationList: formStore.selectedRecords.map((item) => item.id),
    };

    const response = await service.saveFiscalDataForNominations(dataToSave);

    if (response && response.ok) {
      setEnableFMO(true);
      showSuccess('Fiscal data was successfully saved');
    } else {
      showError('Error while saving Fiscal Data.');
    }
  };

  const sendNominationsForward = async () => {
    const nominationsToSend = formStore.selectedRecords.map((item) => item.id);
    const requestData = { nominationList: nominationsToSend, userEmployeeId: employeeId };
    let successMessage = '';
    let errorMessage = '';

    if (isPendingFinancialInfoMode()) {
      successMessage = 'Selected Nomination(s) successfully sent to FMO.';
      errorMessage = 'Error while sending nomination to FMO.';
    }

    if (isPendingReviewMode()) {
      successMessage = 'Selected Nomination(s) successfully sent to Committee.';
      errorMessage = 'Error while sending nomination to Committee.';
    }

    if (isExternalApprovalMode()) {
      successMessage = 'Selected Nomination(s) successfully sent to Bureau.';
      errorMessage = 'Error while sending nomination to Bureau.';
    }

    const response = await service.sendNominationsForward(requestData);

    if (response && response.ok && response.data === true) {
      dispatch({ type: 'REMOVE_SELECTED_NOMINATIONS', data: nominationsToSend });
      showSuccess(successMessage);
      setSelectedRecords([]);

      if (isPendingFinancialInfoMode()) {
        setEnableFMO(false);
      }
    } else {
      showError(errorMessage);
    }
  };

  const goToNomination = (link) => {
    localStorage.setItem(CYCLE_STORAGE_NAME, JSON.stringify(formStore.cycleIds));
    localStorage.setItem(POST_STORAGE_NAME, JSON.stringify(formStore.postIds));
    localStorage.setItem(AWARDPROCESS_STORAGE_NAME, formStore.processTypeFilter);
    localStorage.setItem(NOM_STATUS_STORAGE_NAME, JSON.stringify(formStore.statusIds));
    localStorage.setItem(MISSION_STORAGE_NAME, JSON.stringify(formStore.missionIds));
    localStorage.setItem(AWARD_TYPE_STORAGE_NAME, JSON.stringify(formStore.awardTypeIds));
    history.push(link);
  };

  const idBodyTemplate = (rowData) => {
    return (
      <button
        type="button"
        className="btn btn-link no-padding text-left"
        onClick={() => goToNomination(getLink(rowData.id, rowData.nominationStatus))}
      >
        {rowData.nominationKey}
      </button>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div>
        <div>
          <button
            type="button"
            className="btn btn-link no-padding text-left"
            onClick={() => goToNomination(getLink(rowData.id, rowData.nominationStatus))}
          >
            {rowData.nominationStatusName}
          </button>
          {isPendingReviewMode() && rowData.nominationKey && (
            <FontAwesomeIcon icon={faUndo} className="mx-2" title="Returned Nomination" />
          )}
          {(isDisapprovedMode() ||
            isOngoingMode() ||
            isCompletedMode() ||
            isCeremonyAssignmentMode() ||
            isMyNominationsMode() ||
            isPendingReviewMode() ||
            isMyHistoryMode() ||
            isBureauOngoingMode() ||
            isBureauDecisionMode()) &&
            rowData.nominationStatus !== nominationStatus.DISAPPROVED_BY_SUPERVISOR &&
            rowData.nominationStatus !== nominationStatus.DRAFT && (
              <Button
                className="px-2 py-1 p-button-link"
                onClick={() => openPrintFormModal(rowData.id, rowData.awardProcessType)}
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
            )}
        </div>
        <div className="font-italic">
          <small>Modified {formatDistanceToUtcNow(new Date(rowData.modifiedDate), { addSuffix: true })}&nbsp;ago</small>
        </div>
      </div>
    );
  };

  const awardTypeNameBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.awardTypeName}
        <div>
          <TypeBadge type={rowData.awardProcessType} />
        </div>
      </>
    );
  };

  const missionNameBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.missionName}
        <div>
          <small>{rowData.postName}</small>
        </div>
      </div>
    );
  };

  const renderHeaderNominees = () => {
    return (
      <div>
        Nominees
        <FontAwesomeIcon
          title="USDH is not eligible for cash or time off, only a certificate will be issued."
          className="ml-1"
          icon={faQuestion}
          style={{
            color: 'navy',
            height: 18,
          }}
        />
      </div>
    );
  };

  const nomineeBodyTemplate = (rowData) => {
    const body = rowData.nominationEmployees.length
      ? rowData.nominationEmployees.map((e) => {
          if (e.approvalStatus === approvalStatus.DISAPPROVED) return null;
          return <div key={`${rowData.id}-${e.id}`}>{e.name}</div>;
        })
      : '-';
    return <div className="module line-clamp">{body}</div>;
  };

  const nominatorBodyTemplate = (rowData) => {
    return (
      <div>
        <div>{rowData.nominatorName}</div>
        <div>
          <small>
            Mission/Post: {rowData.missionName}/{rowData.postName}
          </small>
        </div>
        <div>
          <small>
            Agency/Section: {rowData.nominatorAgency}/{rowData.nominatorSection}
          </small>
        </div>
      </div>
    );
  };

  const supervisorBodyTemplate = (rowData) => {
    const body = rowData.nominationEmployees.length
      ? rowData.nominationEmployees.map((e) => {
          if (e.approvalStatus === approvalStatus.DISAPPROVED) return null;
          if (!e.usdhSupervisorName)
            return (
              <i className="text-danger" key={`${rowData.id}-${e.id}`}>
                No Supervisor Assigned
              </i>
            );
          if (isPendingUSDHReviewMode())
            return (
              <Button
                className="px-2 py-1 p-button-link"
                onClick={() => openSupervisorEmailModal(rowData.id, e.usdhSupervisorId, e.usdhSupervisorName)}
              >
                {e.usdhSupervisorName}
              </Button>
            );

          return <div key={`${rowData.id}-${e.id}`}>{e.usdhSupervisorName}</div>;
        })
      : '-';
    return <div className="module line-clamp">{body}</div>;
  };

  const headerNominees = renderHeaderNominees();

  return (
    <>
      <Row>
        <Col md="4">
          <h4>{getTitle()}</h4>
        </Col>
        <Col md="5" className="d-flex justify-content-end">
          <Button
            label="Clear Filters"
            icon="pi pi-times"
            onClick={() => {
              dispatch({ type: 'CLEAR_FILTERS' });
            }}
            className="btn-sm mb-1 mr-1 btn-secondary"
          />
          {!isBureauPendingReviewMode() && !isBureauOngoingMode() && (
            <div className="text-right mt-1 text-nowrap">
              <FontAwesomeIcon
                size="lg"
                icon={faFilter}
                fixedWidth
                className="mb-1 mr-1 color-primary"
                title="Process type filter"
              />
              <span className="p-buttonset ml-1">
                <Button
                  type="button"
                  label="All"
                  className={`btn-sm p-button-primary ${
                    formStore.processTypeFilter === processType.NONE ? '' : 'p-button-outlined'
                  }`}
                  onClick={() => onChangeProcessTypeFilter(processType.NONE)}
                />
                <Button
                  type="button"
                  label="ANYTIME"
                  disabled={formStore.cycleIds && formStore.cycleIds.length > 0 && formStore.cycleIds[0] !== 0}
                  className={`btn-sm p-button-primary ${
                    formStore.processTypeFilter === processType.ANYTIME ? '' : 'p-button-outlined'
                  }`}
                  onClick={() => onChangeProcessTypeFilter(processType.ANYTIME)}
                />
                <Button
                  type="button"
                  label="ANNUAL"
                  className={`btn-sm p-button-primary ${
                    formStore.processTypeFilter === processType.ANNUAL ? '' : 'p-button-outlined'
                  }`}
                  onClick={() => onChangeProcessTypeFilter(processType.ANNUAL)}
                />
              </span>
            </div>
          )}
        </Col>
        <Col md="3">
          <div>
          <span className="p-input-icon-left w-100">
            <i className="pi pi-search" />
            <InputText
              type="Search"
              placeholder="Global Filter"
              onChange={(e) => {
                const inputValue = e.target.value;
                dispatch({ type: 'SET_FILTER_TERM', data: inputValue });

                if (inputValue && inputValue.length > 2 || inputValue === '') {
                  setLazyParams((prev) => ({ ...prev, requestStatus: requestStatus.REQUEST_NOT_INITIATED }));
                }
              }}
              value={formStore.filterTerm}
              className="w-100"
            />
          </span>

          </div>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          {showMissionDropDown && (
            <>
              <h6 className="select-heading">Mission: </h6>
              <MultiSelect
                value={formStore.missionIds}
                options={formStore.missionOptions}
                onChange={(e) => onChangeMultiMission(e.value)}
                className="cycle-multi-dropdown-filter"
                showSelectAll={false}
              />
            </>
          )}
          {!showMissionDropDown && (
            <>
              <h6 className="select-heading">Post:</h6>
              <MultiSelect
                value={formStore.postIds}
                options={formStore.postOptions}
                onChange={(e) => onChangeMultiPost(e.value)}
                className="cycle-multi-dropdown-filter"
                showSelectAll={false}
              />
            </>
          )}
        </Col>
        <Col md="3">
          {!isCeremonyAssignmentMode() && (
            <>
              <h6 className="select-heading">Cycle:</h6>
              {renderMultiCycleDropDown()}
            </>
          )}
          {isCeremonyAssignmentMode() && (
            <>
              <h6 className="select-heading">Ceremony:</h6>
              {renderCeremonyDropdown()}
            </>
          )}
        </Col>
        {(isMyNominationsMode() ||
          isOngoingMode() ||
          isBureauDecisionMode() ||
          isBureauOngoingMode() ||
          isSendToOPSMode() ||
          isSupervisorOngoingMode()) && (
          <Col md="3">
            <h6 className="select-heading">Status:</h6>
            <MultiSelect
              value={formStore.statusIds}
              options={formStore.nominationStatusOptions}
              onChange={(e) => onChangeMultiNominationStatus(e.value)}
              className="cycle-multi-dropdown-filter"
              showSelectAll={false}
            />
          </Col>
        )}
        {!isBureauUser && (
          <Col md="3">
            <h6 className="select-heading">Award Type:</h6>
            <MultiSelect
              value={formStore.awardTypeIds}
              options={formStore.awardTypeOptions}
              onChange={(e) => onChangeMultiAwardType(e.value)}
              className="cycle-multi-dropdown-filter"
              showSelectAll={false}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md="12">
          {isSendToOPSMode() && (
            <>
              <Button
                label="Assign Effective Date"
                icon="pi pi-calendar"
                onClick={() =>
                  openSendToOPSModal(nominationStatus.PENDING_EFFECTIVE_DATE, false, nominationAction.FORWARD)
                }
                disabled={!hasItemSelected(nominationStatus.PENDING_EFFECTIVE_DATE)}
                className="min-w-240px btn-sm"
              />
              <Button
                icon="pi pi-send"
                onClick={() =>
                  openSendToOPSModal(nominationStatus.PENDING_SEND_TO_OPS, false, nominationAction.FORWARD)
                }
                disabled={
                  formStore.selectedRecords?.length === 0 || !hasAllItemSelected(nominationStatus.PENDING_SEND_TO_OPS)
                }
                className="ml-2 min-w-240px btn-sm"
                label="Send To OPS"
              />
              <Button
                icon="pi pi-times"
                onClick={() => openSendToOPSModal(nominationStatus.COMPLETED, true, nominationAction.SKIPSTEP)}
                disabled={formStore.selectedRecords.length === 0}
                className="ml-2 min-w-240px btn-sm p-button-danger"
                label="Exclude from Send to OPS"
              />
            </>
          )}
          {isCeremonyAssignmentMode() && (
            <>
              <Button
                label="Assign Ceremony"
                icon="pi pi-calendar"
                onClick={() => openAssignCeremonyModal(ASSIGN_CEREMONY_MODE)}
                disabled={!formStore.selectedRecords.length > 0}
                className="min-w-240px btn-sm"
              />
              <Button
                icon="pi pi-send"
                onClick={() => openAssignCeremonyModal(GENERATE_SCRIPT_MODE)}
                className="ml-2 min-w-240px btn-sm"
                label="Generate Ceremony Script"
                disabled={formStore.ceremonyId <= 0 || formStore.selectedRecords.length === 0}
              />
            </>
          )}
        </Col>
      </Row>
      <div className="container-fluid table-responsive">
        {formStore.dataFetching === requestStatus.REQUEST_IN_PROGRESS && <SkeletonTable rowsSize={5} colsSize={5} />}

        {formStore.dataFetching === requestStatus.REQUEST_COMPLETED && (
          <>
            <Grid
              items={formStore.loadedNominations}
              selectedRecords={formStore.selectedRecords}
              onSelectionChange={(e) => {
                setSelectedRecords(e.value);
              }}
              dataKey="id"
              sortMode="single"
              totalRecords={formStore.totalRecords}
              lazyParams={lazyParams}
              setLazyParamsFn={setLazyParams}
            >
              {isCheckboxEnabled && <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />}
              <Column field="nominationKey" header="ID" body={idBodyTemplate} sortable />
              <Column field="nominationStatus" header="Status" body={statusBodyTemplate} sortable />
              <Column field="awardTypeName" header="Award Type" body={awardTypeNameBodyTemplate} sortable />
              {isBureauPendingReviewMode && (
                <Column field="missionName" header="Mission/Post" body={missionNameBodyTemplate} sortable />
              )}
              <Column field="nominationEmployees" header={headerNominees} body={nomineeBodyTemplate} sortable />
              <Column field="nominationSupervisors" header="Supervisors" body={supervisorBodyTemplate} sortable />
              <Column field="nominatorName" header="Nominator" body={nominatorBodyTemplate} sortable />
            </Grid>
            <div className="row mx-2 my-4">
              {formStore.selectedRecords.length > 0 && (
                <>
                  {isPendingFinancialInfoMode() && (
                    <div className="d-flex w-50">
                      <InputText
                        className="w-100 form-control"
                        style={{ width: '50%' }}
                        value={fiscalStripValue ?? ''}
                      />
                      <span className="show-item mx-2">
                        <Button
                          label="Save"
                          className="p-button-success form-control show-clear"
                          onClick={onSaveFiscalStrip}
                          hidden={!formStore.fiscalStripUpdated}
                        />
                      </span>
                      <span className="show-item mx-2">
                        <Button
                          label={`${fiscalStripValue ? 'Edit' : 'Create'}  Fiscal Data`}
                          className="p-button-primary form-control show-clear"
                          style={{ width: 'max-content' }}
                          onClick={openFiscalStripModal}
                        />
                      </span>
                      <span className="show-item mx-2">
                        <Button
                          label="Send to FMO"
                          className="p-button-secondary"
                          style={{ width: 'max-content' }}
                          onClick={sendNominationsForward}
                          hidden={
                            !(
                              formStore.selectedRecords.every((x) => x.isFiscalStripAssignedToAllNominees === true) &&
                              enableFMO
                            )
                          }
                        />
                      </span>
                    </div>
                  )}
                  {isExternalApprovalMode() &&
                    isBureauEnabled &&
                    formStore.selectedRecords.every(
                      (x) => x.nominationStatus === nominationStatus.PENDING_EXTERNAL_APPROVAL
                    ) && (
                      <div className="d-flex m-auto">
                        <span className="show-item mx-2">
                          <Button
                            label="Send to Bureau"
                            className="p-button-primary form-control show-clear"
                            onClick={sendNominationsForward}
                            icon="pi pi-send"
                          />
                        </span>
                      </div>
                    )}
                </>
              )}
            </div>
          </>
        )}
        <div className="debug mt-3">
          debug Info =&gt; list Mode:
          {mode}
        </div>
      </div>
      {isSendToOPSMode() && (
        <SendToOPSModal
          isSendToOPSModalOpened={formStore.isSendToOPSModalOpened}
          onClickToggleModal={closeSendToOPSModal}
          modalMode={formStore.sendToOPSModalMode}
          listToProcess={formStore.sendToOPSModalList}
          setEffectiveDateAssigned={setEffectiveDate}
          isExcludeFromOPS={formStore.isExcludeFromOPS}
          nominationAction={formStore.nominationAction}
        />
      )}
      {isCeremonyAssignmentMode() && (
        <AssignCeremonyModal
          isAssignCeremonyModalOpened={formStore.isAssignCeremonyModalOpened}
          onClickToggleModal={closeAssignCeremonySModal}
          ceremonyID={formStore.ceremonyId}
          missionName={currentMissionName}
          listToProcess={formStore.assignCeremonyModalList}
          ceremonyList={formStore.loadedCeremonies}
          ceremonyNominations={formStore.selectedRecords}
          ceremonyName={formStore.ceremonyName}
          modalMode={ceremonyMode}
        />
      )}
      {formStore.awardProcessType && (
        <PrintFormModal
          isModalOpened={formStore.isPrintFormModalOpened}
          onClickCloseModal={onClickClosePrintModal}
          nominationId={formStore.currentNominationId}
          awardProcessType={formStore.awardProcessType}
          nomination={formStore.filteredNominations.filter((x) => x.id === formStore.currentNominationId)[0]}
          isCertificateOnly={
            formStore.filteredNominations.filter((x) => x.id === formStore.currentNominationId)[0]
              ?.formOfRecognition === formOfRecognition.CERTIFICATE_ONLY
          }
        />
      )}

      <SupervisorEmailModal
        isModalOpened={formStore?.isSupervisorEmailModalOpened}
        onClickCloseModal={closeSupervisorEmailModal}
        nominationId={formStore.nominationId}
        supervisorId={formStore.supervisorId}
        supervisorName={formStore.supervisorName}
      />

      {isPendingFinancialInfoMode() && formStore?.isFiscalStripModalOpen && (
        <FiscalStripModal
          fiscalData={formStore.fiscalData}
          agenciesAndBureaus={formStore.availableParams}
          isFiscalStripModalOpen={formStore?.isFiscalStripModalOpen}
          onClickToggleModal={closeFiscalStripModal}
          selectedFiscalStrip={fiscalStripValue}
          onSave={onUpdateFiscalStrip}
        />
      )}
    </>
  );
}

export default NominationsList;
