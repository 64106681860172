import React, { useReducer, useEffect, useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/pro-light-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { approvalStatus, requestStatus } from 'constants/index';
import { useNominationService } from 'hooks/nomination';
import { formatDate } from 'utils/dateHelpers';
import stateSeal from 'assets/images/us-department-of-state-1.svg';
import PropTypes from 'prop-types';
import { nominationStatus, formOfRecognition } from '../../../constants/index';
import FormBlock from './FormBlock';
import './Form.css';

const initState = {
  requestStatus: requestStatus.REQUEST_NOT_INITIATED,
  submissionDate: null,
  approvalDate: null,
  finalApprovedDate: null,
  chairApprovalDate: null,
  chairName: null,
  bureauChairApprovalDate: null,
  bureauChairName: '',  
  nominatorName: '',
  nominatorTitle: '',
  nominatorAgency: '',
  nominationKey: '',
  missionName: '',
  postName: '',
  proposedAmount: '',
  jcacApprovedAmount: '',
  approvedAmount: '',
  finalApproverName: '',
  awardReasons: [],
  formOfRecognition: '',
  postBugetOfficer: '',
  justification: '',
  citation: '',
  periodOfPerformanceStartDate: null,
  periodOfPerformanceEndDate: null,
  nominationEmployees: [],
  otherReason: null,
  execApprovalDate: null,
  execOfficerName: null,
  financeOfficerName: null,
  chairTitle: null,
  execOfficerTitle: null,
  financeOfficerTitle: null,
  jcacApprovalStatus: null,
  execApprovalStatus: null,
  bureauApprovalStatus: null,
  bureauChairRemark: null,
  execRemark: null,
};

const awardReasonValues = [
  [
    'Performance',
    'Special Act'
  ],
  [
    'Customer Service',
    'Innovation',
  ],
  [
    'Teamwork',
    'Crisis Management',
  ],
  [
    '',
    'Other ____________________',
  ],  
]

const typeOfAwardRecommended = [
  [
    "The Secretary's Award",
    'Award for Heroism',
    "Secretary's Career Achievement Award",
    "Distinguished Honor Award",
  ],
  [
    "Superior Honor Award",
    "Meritorious Honor Award",
    "Franklin Award",
    "Foreign Affairs Award for Public Service",
  ],
  [
    "",
    "Time Off From Duty Award",
    "Cash",
    "Other __________________",
  ],
];

const approvals = [
  'Approve',
  'Disapprove',
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, requestStatus: requestStatus.REQUEST_IN_PROGRESS };

    case 'LOAD_DATA':
      return {
        ...state,
        ...action.data,
        requestStatus: requestStatus.REQUEST_COMPLETED,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
// eslint-disable-next-line react/display-name
const DS66 = React.forwardRef(({ nominationId }, ref) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const service = useNominationService();
  const hiddenDivRef = useRef(null);
const [firstPartJustification, setFirstPartJustification] = useState('');
const [secondPartsJustification, setSecondPartsJustification] = useState([]);

const emptyLineRegex = /(<p> <\/p>\s*)+$/;
const periodOfPerformance = `<p>Period Of Performance: ${formatDate(state.periodOfPerformanceStartDate)} - ${formatDate(state.periodOfPerformanceEndDate)}</p>`;

// Combine citation and justification
let citationAndJustification = '';
if(state.citation !== "" && state.justification !== ""){
  citationAndJustification = `${state.justification}<br /><br />${state.citation}`;
} else {
  citationAndJustification = state.justification || state.citation || '';
}

// Add period of performance if available
if(state.periodOfPerformanceStartDate && state.periodOfPerformanceEndDate){
  citationAndJustification = periodOfPerformance + citationAndJustification;
}

// Remove extra empty lines and <br> tags
citationAndJustification = citationAndJustification
  .replace(emptyLineRegex, '')
  .replace(/<br\s*\/?>\s*$/, ''); // Remove trailing <br> tags

// Replace headings with <a> tags
const replaceHeadingsWithA = (htmlString) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  const headings = tempDiv.querySelectorAll('h1, h2, h3, h4, h5');
  headings.forEach(heading => {
    const anchor = document.createElement('a');
    anchor.innerHTML = heading.innerHTML;
    heading.replaceWith(anchor);
  });

  return tempDiv.innerHTML;
};

citationAndJustification = replaceHeadingsWithA(citationAndJustification);



useEffect(() => {
  if (citationAndJustification && citationAndJustification.trim() !== "") {
    const hiddenDiv = hiddenDivRef.current;

    if (hiddenDiv) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(citationAndJustification, 'text/html');
      const elements = Array.from(doc.body.children);

      hiddenDiv.innerHTML = '';
      hiddenDiv.style.cssText = 'position: absolute; top: -9999px; left: -9999px;';

      let accumulatedElements = [];
      let splitIndex = elements.length;

           /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < elements.length; i++) {
        hiddenDiv.appendChild(elements[i].cloneNode(true));
        if (hiddenDiv.scrollHeight > 590) {
          splitIndex = i;
          break;
        }
        accumulatedElements.push(elements[i].outerHTML);
      }

      const firstPart = accumulatedElements.join('');
      if (firstPart !== firstPartJustification) {
        setFirstPartJustification(firstPart);
      }

      if (splitIndex < elements.length) {
        const secondPartElements = elements.slice(splitIndex);
        const secondPartsTemp = [];
        
        accumulatedElements = [];
             /* eslint-disable-next-line no-plusplus */
        for (let i = 0; i < secondPartElements.length; i++) {
          accumulatedElements.push(secondPartElements[i].outerHTML);
          hiddenDiv.innerHTML = accumulatedElements.join(' ');
          
          if (hiddenDiv.scrollHeight > 1200) {
            secondPartsTemp.push(accumulatedElements.join(' '));
            accumulatedElements = [];
          }
        }
        
        if (accumulatedElements.length) {
          secondPartsTemp.push(accumulatedElements.join(' '));
        }

        const arraysAreEqual = (arr1, arr2) => 
          arr1.length === arr2.length && arr1.every((val, index) => val === arr2[index]);

        setSecondPartsJustification(prevSecondParts => {
          if (!arraysAreEqual(secondPartsTemp, prevSecondParts)) {
            return secondPartsTemp;
          }
          return prevSecondParts;
        });
      }
    }
  }
}, [citationAndJustification, firstPartJustification]);

const arraysAreEqual = (arr1, arr2) => 
  arr1.length === arr2.length && arr1.every((val, index) => val === arr2[index]);

  useEffect(() => {
    const loadData = async () => {
      dispatch({ type: 'START_LOADING' });
      const response = await service.getFormData(nominationId);
      if (response && response.ok) {        
        dispatch({ type: 'LOAD_DATA', data: response.data });
      }
    };
    if (state.requestStatus === requestStatus.REQUEST_NOT_INITIATED) {
      loadData();
    }
  }, [nominationId, service, state.requestStatus]);

  const GreyHeaderRow = useCallback((text) => {
    return (
      <div className="row ">
        <div className="col-12 center-block text-center bg-light pt-0 px-0 border-b align-items-center">
          <p className="h6 pt-2">{text}</p>          
        </div>
      </div>
    );
  }, []);

  const GreyHeaderRowWithItalic = useCallback((text, italicText) => {
    return (
      <div className="row">
        <div className="col-12 bg-light pt-0 px-0 border-b d-flex align-items-center m-0">
          <p className="h6 mb-0 pl-2">{text}</p>
          {italicText && <i className="pl-2">{italicText}</i>}
        </div>
      </div>
    );
  }, []);
  

  const getUnitSymbol = (option) => {
    if (!option) return '';
    return option === formOfRecognition.CASH_ONLY ? 'USD' : 'Hours';
  };

  const renderCheckbox = (field, text) => {
    let values = [];

    if(field === 'awardReasons'){
      values = state.awardReasons.map((item) => item.awardReasonName?.toLowerCase());
    }    

    if(field === 'awardTypeName'){     
      const awardType = typeOfAwardRecommended.flat().map(x => x.toLowerCase()).includes(state.awardTypeName.toLowerCase()) ? state.awardTypeName : 'other';
      let awardTypeName = '';
      if(awardType.toLowerCase() === 'other'){
        awardTypeName = 'Other __________________';
      } else{
        awardTypeName = state.awardTypeName;
      }
      values = [awardTypeName.toLowerCase()];
    }

    if(field === 'jcacApproval'){
      values = [state.jcacApprovalStatus === "" ? null : state.jcacApprovalStatus?.toLowerCase() ];
    }

    if(field === 'execApproval'){
      values = [state.execApprovalStatus === "" ? null : state.execApprovalStatus?.toLowerCase()];
    }

    if(field === 'bureauApproval'){
      values = [state.bureauApprovalStatus === "" ? null : state.bureauApprovalStatus.toLowerCase()];
    }

    if(field === 'deptAwardCommitteeApproval'){
      values = [];
    }

    if(text === undefined) return null;

    if(text === '') return <p />;

    return (
     <>    
        <FontAwesomeIcon
          icon={
            values.includes(text.toLowerCase()) ? faCheckSquare: faSquare
          }
          size="lg"
          className='py-0'
        />{' '}{text}   
    </>
    );    
  };

  const renderSupervisors = () => {
  
    const approvingSupervisors = state.nominationEmployees
      .filter(x => x.approvalStatus === approvalStatus.APPROVED);

    const uniqueSupervisors = approvingSupervisors.filter(x => x.usdhSupervisorId !== state.nominatorId).map(
      (item) => item?.usdhSupervisorName
    );;

    if(uniqueSupervisors.length === 0) return '';
    return (
      <>
        {uniqueSupervisors
          .filter((q, idx) => uniqueSupervisors.indexOf(q) === idx)
          .map((item) => (
            <span className="pr-2" key={item?.uSDHSupervisorId}>
              {`/s/ ${item}`}
              {'; '}
            </span>
          ))}
      </>
    );
  };

  const checkAgency =() =>
  {
    const isState = state.nominationEmployees.filter(x => x.approvalStatus === approvalStatus.APPROVED).some(obj => obj?.agencyCode?.includes('ST')) 
    const isUsaid = state.nominationEmployees.filter(x => x.approvalStatus === approvalStatus.APPROVED).some(obj => obj?.agencyCode?.includes('AM'))
    return { isState,  isUsaid}
  } 

  const renderIndividualFiscalStrip = () => {
    const approvedNominees = state.nominationEmployees.filter(x => x.approvalStatus === approvalStatus.APPROVED);    
    const fiscalStrip = approvedNominees.length === 1 ? `${approvedNominees[0].fiscalStrip ?? 'N/A'} ${state.approvedAmount}` : 'See Below';
    return (    
        <div className="row">
          <div className="col-10 border-r">
            <div className='row'>
              <div className='px-2'>     
                <strong>Accounting Classification</strong> <i>(Completed by Bureau/Post Budget Officer)</i>
              </div>        
             <div className="row px-2">
                <div className='col-1'>
                  <small>Agency</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Appropriation</small>
                </div>
                <div className='col-1 text-center'>
                  <small>Allotment</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Obligation No.</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Org. Code</small>
                </div>
                <div className='col-1 text-center'>
                  <small>Function</small>
                </div>
                <div className='col-1 text-center'>
                  <small>Object</small>
                </div>
                <div className='col-2 text-center'>
                  <small>Award Amount</small>
                </div>                
              </div>
            </div>
            <div className='text-break'>            
            {state.formOfRecognition === formOfRecognition.CASH_ONLY ? fiscalStrip : ''}
            </div>
          </div>
          <div className="col-2 text-center">
              <div className=' text-center bg-light row border-b '>
              <div className="col-12 center-block text-center">
                <strong style={{fontSize : "0.85rem", padding: 0}}>For Gift Cheque Use Only</strong>
              </div>          
              </div>
              <div className=' row border-b '>
              <div className="col-6 center-block text-center border-r" style={{height:'50px'}}>
                  Obligation
                </div>
                <div className="col-6 center-block text-center">
                  Net
                </div>
              </div>
          </div>
        </div>   
    );
  };

  return (
    <>
    {state.requestStatus === requestStatus.REQUEST_IN_PROGRESS && (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )}
    {state.requestStatus === requestStatus.REQUEST_COMPLETED && (

    <div ref={ref} key={state.nominationKey} className="px-3">
      <div className="container-fluid mb-0 pt-0 pb-0 printable-form">       
        {GreyHeaderRow('FOR OFFICE USE')}
        <div className="row border-b">
          <div className="col-2 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <><span>State </span>
                  <FontAwesomeIcon
                  icon={
                    checkAgency().isState
                      ? faCheckSquare
                      : faSquare
                  }
                  size="lg"
                />
                  <span className='ml-2'>USAID </span>
                  <FontAwesomeIcon
                  icon={
                    checkAgency().isUsaid
                      ? faCheckSquare
                      : faSquare
                  }
                  size="lg"
                /></>,
                },
              ]}
            />
          </div>
          <div className="col-5 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <span>Date Received by Awards Office <i>(mm-dd-yyyy)</i></span>,
                  line2: formatDate(state.usdhApprovalDate),
                },
              ]}
            />
          </div>          
          <div className="col-5">
            <FormBlock
              Rows={[
                {
                  line1: <span>Date Related to Personnel Records <i>(mm-dd-yyyy)</i></span>,                  
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12">
            <div className="pl-2 pt-2 pb-2 text-center">
              <div className=" float-left position-absolute">
                <img
                  className="usseal"
                  src={stateSeal}
                  alt="US Department of State"
                />
              </div>
            </div>
            <div className="text-center font-arial">
                U.S. Department of State
            </div>        
            <div className="pl-2 text-center h2 font-weight-bold font-arial">
              NOMINATION FOR AWARD
            </div>
          </div>
        </div>
        {GreyHeaderRow('PART I - NOMINATION ')}
        <div className="row border-b">
          <div className="col-6 border-r py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: <span>Name of Nominee <i>(Last, First, MI)</i></span>,
                  line2: state.nominationEmployees?.length === 1 ? state.nominationEmployees[0]?.name : state.nominationKey,
                },
              ]}
            />                        
          </div>
          
          <div className="col-3 border-r py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Social Security Number',
                  line2: !state.isGroup ? state.nominationEmployees[0]?.payrollId : ''
                },
              ]}
            /> </div>
          <div className="col-3 py-1 mb-0">

          <FormBlock
              Rows={[
                {
                  line1: 'ORG Symbol or Post',
                  line2: !state.isGroup ? state.nominationEmployees[0]?.postName : '',
                },
              ]}
            /> 
          </div>
        </div>

        <div className="row border-b">
          <div className="col-6 border-r py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Present Position Title and Grade',
                  line2: !state.isGroup ? `${state.nominationEmployees[0]?.positionTitle},  ${state.nominationEmployees[0]?.grade ?? ''}`  : '',
                },
              ]}
            />                        
          </div>    
          <div className="col-6 py-1 mb-0">
          <FormBlock
              Rows={[
                {
                  line1: 'Position held during period covered by nomination if different than present',
                  line2: '',
                },
              ]}
            />             
          </div>            
        </div>
        
        {GreyHeaderRow('REASON FOR AWARD')}
        <div className="row border-b">
          {awardReasonValues.map((item, idx) => (
            <div className="col-3">
              <FormBlock
                Rows={[
                  {
                    line1: <span>{renderCheckbox('awardReasons', item[0])}</span>,
                    line2: <span>{renderCheckbox('awardReasons', item[1])}</span>,
                  },
                ]}
              />
            </div>
          ))}
        </div>
        {GreyHeaderRow('TYPE OF AWARD RECOMMENDED')}
        <div className="row border-b">
          {typeOfAwardRecommended.map((item, idx) => (
            <div className="col-4">
              <FormBlock
                Rows={[
                  {
                    line1: <span>{renderCheckbox('awardTypeName', item[0])}</span>,
                    line2: <span>{renderCheckbox('awardTypeName', item[1])}</span>,
                    line3: <span>{renderCheckbox('awardTypeName', item[2])}</span>,
                    line4: <span>{renderCheckbox('awardTypeName', item[3])}</span>,
                  },
                ]}
              />
            </div>
          ))}
        </div>

        <div className="row border-b">
          <div className="col-6 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <span>Recommended Amount <i>(Cash/Time Off Hours)</i></span>,
                  line2: <span>{`${state.proposedAmount} ${ getUnitSymbol(state.proposedFormOfRecognition)}`}</span>  ,
                },
              ]}
            />
          </div>
          <div className="col-3 bg-light text-center border-l">
            <FormBlock
              Rows={[
                {
                  line1: <span className='font-weight-bold'>Approved Amount</span>,
                  line2: <span>{`${state.approvedAmount} ${ getUnitSymbol(state.formOfRecognition)}`}</span>
                },
              ]}
            />
          </div>
          <div className="col-3 bg-light text-center">
            <FormBlock
              Rows={[
                {
                  line1: <span className='font-weight-bold'>Approved Award</span>,
                  line2: state.awardTypeName
                },
              ]}
            />
          </div>
        </div>

        <div className="row border-b">
          <div className="col-12" style={{height:"650px"}}>
            <FormBlock
              Rows={[
                {
                  line1: <span>Justification for Award <i>(Include a concise citation to be used on the award certificate. Additional sheets may be used.)</i></span>,
                  line2: (
                    <div 
                      dangerouslySetInnerHTML={{ __html: firstPartJustification }}
                    />
                  ),                 
                },
              ]}
            />
          </div>
        </div>        
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <span>Nominated By <i>(Name, Title, Signature)</i></span>,
                  line2: `${state.nominatorName}  -  ${state.nominatorTitle ?? 'N/A'}`,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.submissionDate),
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1:
                    <span>Approved By<i>(Supervisor’s Name, Title, Signature).  Applicable only if nominated by other than supervisor.</i></span>,
                  line2: <span>{renderSupervisors()}</span>,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.usdhApprovalDate),
                },
              ]}
            />
          </div>      
        </div>       
        </div>     
           
      
        <div className="container-fluid mb-0 pt-0 pb-0 printable-form page-break">
          {GreyHeaderRowWithItalic('PART II – ACTION TAKEN/TIME OFF FROM DUTY AWARD.', 'Optional – for period not to exceed one work day.')}
        <div className="row border-b">
          <div className="col-7 border-r px-0">
            <div className="border-b">
              <div className="col-12">
                <FormBlock
                  Rows={[
                    {
                      line1: <span>Bureau/Post Approval <i>(Name, Title)</i></span>,
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="col-12">
                <FormBlock
                  Rows={[
                    {
                      line1: <span>Bureau/Post Approval <i>(Signature)</i></span>,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-5">
            <span>Date <i>(mm/dd/yyyy)</i></span>
          </div>
        </div>

    {GreyHeaderRow(
      'PART III - ACTION TAKEN BY JOINT COUNTRY AWARDS COMMITTEE'
    )}

    <div className="row border-b">
      <div className="col-6 border-r">
        <div className="row border-b">
          <div className="col-6 border-r px-0">      
           {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('jcacApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          }    
          </div>
          <div className="col-6">            
            <FormBlock
              Rows={[
                {
                  line1: <span>Date <i>(mm/dd/yyyy)</i></span>,
                  line2: formatDate(state.chairApprovalDate),
                },
              ]}
            />
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: state.formOfRecognition === formOfRecognition.CASH_ONLY ? state.jcacApprovedAmount : <span />,
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name of Committee Chairperson',
                  line2: state.chairName ?? ''
                },
              ]}
            />
          </div>        
          </div>
          <div className="col-6">
            <strong>Remarks</strong>
              <p>{`JCAC ${state.jcacApprovalStatus?.toLowerCase()} ${ getUnitSymbol(state.formOfRecognition)} ${state.jcacApprovedAmount}`}</p> 
          </div>
    </div>
        <div className="row border-b">          
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Signature of Committee Chairperson',
                      line2: state.chairName ? `/s/ ${state.chairName}` : '',
                    },
                  ]}
                />              
          </div>  
        </div>
      {GreyHeaderRow('PART IV - ACTION TAKEN BY CHIEF OF MISSION')}                         
      <div className="row border-b">
      <div className="col-6 border-r">
        <div className="row border-b">
        <div className="col-6 border-r px-0">
        {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('execApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          }
          </div>
          <div className="col-6">
          <FormBlock
              Rows={[
                {
                  line1: <span>Date <i>(mm/dd/yyyy)</i></span>,
                  line2: formatDate(state.execApprovalDate),
                },
              ]}
            />
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: state.proposedFormOfRecognition === formOfRecognition.CASH_ONLY ? (<span>{`${state.approvedAmount} ${ getUnitSymbol(state.proposedFormOfRecognition)}`}</span>) : <span />,
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name of Chief of Mission',
                  line2: state.execOfficerName ?? ''
                },
              ]}
            />
          </div>        
          </div>
          <div className="col-6">
            <strong>Remarks</strong>
            <div className="d-flex flex-column h-75 ">    
          <div className=''>
            <span>{state.execRemark}</span>
          </div>    
          </div>
          </div>
        </div>

        <div className="row border-b">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Signature of Chief of Mission',
                      line2:  state.execOfficerName ? `/s/ ${state.execOfficerName}` : '',
                    },
                  ]}
                />
          </div>
        </div>

        {GreyHeaderRow('PART V - ACTION TAKEN BY AREA AWARDS COMMITTEE')}

        <div className="row border-b">
        <div className="col-6 border-r">
        <div className="row border-b">
          <div className="col-6 border-r px-0">          
          {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('bureauApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          } 
          </div>
          <div className="col-6">
          <FormBlock
              Rows={[
                {
                  line1: <span>Date <i>(mm/dd/yyyy)</i></span>,
                  line2: formatDate(state.bureauChairApprovalDate),
                },
              ]}
            />
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: state.bureauApprovalStatus?.toLowerCase() === 'approved' && state.approvedAmount
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name of Committee Chairperson',
                  line2: state.bureauChairName ?? ''
                },
              ]}
            />
          </div>        
          </div>
          <div className='col-6'>
          <div className=""><strong>Remarks</strong></div>
          <div className="d-flex flex-column h-75 ">
            <div className="mt-auto">              
              <small>Certification: All Committee members reviewing this nomination have attended
              Diversity Awareness Training for awards committee members.</small>
              </div>
          </div>
          </div>
        </div>
        <div className="row border-b">          
          <div className="pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Signature of Committee Chairperson',
                  line2:  state.bureauChairName ? `/s/ ${state.bureauChairName}` : '',
                },
              ]}
            />              
          </div>  
        </div>        

        {GreyHeaderRow(
          'PART VI - ACTION TAKEN BY DEPARTMENT AWARDS COMMITTEE'
        )}

    <div className="row border-b">
      <div className="col-6 border-r">
        <div className="row border-b">
          <div className="col-6 border-r px-0">          
            <div className="pl-2 ">
            {
            approvals.map((item, idx) => (
              <div className="pl-2 " key={item}>
                <FormBlock
                  Rows={[
                    {
                      line1: <span className=''>{renderCheckbox('deptAwardCommitteeApproval', item)} </span>,
                    },
                  ]}
                />
              </div>
            ))
          } 
              </div>
          </div>
          <div className="col-6">
            <span>Date <i>(mm/dd/yyyy)</i></span>
          </div>
        </div>        
          <div className="row border-b pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Cash Awards Only - Approved Amount',
                  line2: ''
                },
              ]}
            />
          </div>
          <div className="row  pl-2 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Typed Name and Title',
                  line2: ' '
                },
              ]}
            />
          </div>        
          </div>
          <div className="col-6">
            <strong>Remarks</strong>
          </div>
        </div>

        <div className="row border-b">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Signature',
                    },
                  ]}
                />
          </div>
        </div>
        {GreyHeaderRow('PART VII - FISCAL DATA')}
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1: <span>Bureau/Post Budget Officer <i>(Name, Signature)</i></span>,
                  line2: state.formOfRecognition === formOfRecognition.CASH_ONLY ? (state.financeOfficerName ?? '') : <span /> ,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: state.formOfRecognition === formOfRecognition.CASH_ONLY ? formatDate(state.financeApprovalDate) : <span />
                },
              ]}
            />
          </div>
        </div>
      {renderIndividualFiscalStrip()}
      {GreyHeaderRow('PART VIII - PAYROLL OFFICE INFORMATION - FOR GIFT CHEQUE USE ONLY')}
      <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1:
                     <span>Bureau/Post Awards Officer <i>(Name, Signature)</i></span>,
                  line2: '',
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: <span>Date <i>(mm/dd/yyyy)</i></span>,
                  line2: '',
                },
              ]}
            />
          </div>
        </div>
        <div className='border-b row px-2'>
          <strong>Payroll Information</strong> <i> (Completed by FMP)</i> 
        </div>
        <div className='row'  style={{height:'60px'}}>
          <div className='col-2 border-r'>
            <small>Gross Amount</small>
          </div>
          <div className='col-2 border-r'>
            <small>Federal Tax Withheld</small>
          </div>
          <div className='col-2 border-r'>
            <small>State Tax Withheld</small>
          </div>
          <div className='col-2 border-r'>
            <small>OASDI Tax Withheld</small>
          </div>
          <div className='col-2 border-r'>
            <small>FHI Tax Withheld</small>
          </div>
          <div className='col-2'>
            <small>Net Amount</small>
          </div>
        </div>
        </div>  
       
              {state.isGroup && 
         
         <div className="page-break container-fluid mb-0 pt-0 pb-0 printable-form">
          {GreyHeaderRow('NOMINEES')}
            {GreyHeaderRow(  
                <div className="row  px-3">
                <div className="col-3 ">Name</div>
                <div className="col-2 ">Social Security Number</div>
                <div className="col-2 ">Post</div>
                <div className="col-3 ">Title and Grade</div>
                <div className="col-2 ">Previous Title</div>
              </div>
              )
            }
         
            {state.nominationEmployees
              .filter((x) => x.approvalStatus === approvalStatus.APPROVED)
              .map((nominee, idx) => (
                <div
                  className="row border-b "
                  key={nominee.employeeId}
                >
                  <div className="col-3 border-r">
                    <FormBlock
                      Rows={[
                        {
                          line2: nominee.name,
                        },
                      ]}
                    />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-3 border-r">
                        <FormBlock
                          Rows={[
                            {
                              line2: `${nominee.payrollId}`,
                            },
                          ]}
                        />
                      </div>       
                      <div className="col-2 border-r">
                        <FormBlock
                          Rows={[
                            {
                              line2: `${nominee.postName}`,
                            },
                          ]}
                        />
                      </div>
                      <div className="col-4 border-r">
                        <FormBlock
                          Rows={[
                            {
                              line2: `${nominee.positionTitle}, ${nominee.grade ?? ''}`
                            },
                          ]}
                        />
                      </div>
                      <div className="col-3">
                        <FormBlock
                          Rows={[
                            {
                              line2: nominee.previousPositionTitle,
                            },
                          ]}
                        />
                      </div>
                
                  </div>
                  {nominee.fiscalStrip && <div className='row border-top'>                 
                    <span className='m-1 ml-3'>{`Fiscal strip: ${nominee.fiscalStrip}/ $${state.approvedAmount} `}</span>
                      </div>}
                    </div>                        
                </div>
            ))} 
         </div>            
        } 
         {secondPartsJustification.length > 0 && 
            secondPartsJustification.map((part, index) => (
              <div key={uuidv4()} className="container-fluid mb-0 pt-0 pb-0 printable-form" style={{height: '98vh', pageBreakAfter: index < secondPartsJustification.length - 1 ? 'always' : 'auto' }}>
                {GreyHeaderRowWithItalic('Justification for Award Continuation Sheet', '(Include a concise citation to be used on the award certificate.)')}
                <div className="row"> 
                  <div className='col-12'>
                    <FormBlock
                      Rows={[
                        {
                          line1: (
                            <div
                              dangerouslySetInnerHTML={{ __html: part }}
                            />
                          ),
                        },
                      ]}
                    /> 
                  </div>
                </div>
              </div>
            ))
          }
          <div className="container-fluid mb-0 pt-0 pb-0 print-hide" ref={hiddenDivRef} style={{visibility: 'hidden', display: 'none', pageBreakAfter: 'avoid'}} />
        </div>
    )}
    </>
  );
});

DS66.propTypes = {
  nominationId: PropTypes.number,
};

DS66.defaultProps = {
  nominationId: null,
};

export default DS66;